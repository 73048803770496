// function getVertexShaderCode() {
//     return `uniform float uniTime;
//     attribute vec2 attCoords;
//     varying vec2 varUV;
//     varying float varSlope;
//     void main() {
//         varUV = attCoords;
    
//         float x = attCoords.x;
//         float y = attCoords.y;
    
//         float h = cos( uniTime + x * 10.0 );
//         h += cos( x * 3.0 - uniTime * 0.1751 );
//         y += h * x * 0.2;
    
//         float x2 = x - 0.001;
//         float h2 = cos( uniTime + x2 * 10.0 );
//         h2 += cos( x2 * 3.0 - uniTime * 0.1751 );
//         varSlope = h - h2;
    
//         gl_Position = vec4( 2.0 * x - 1.0, 0.5 - y, 0.0, 1.0 );
//     }`;
// };
  
// function getFragmentShaderCode() {
//     return `precision mediump float;
//     uniform sampler2D uniTexture;
//     varying vec2 varUV;
//     varying float varSlope;
    
//     void main() {
//         vec4 color = texture2D( uniTexture, varUV );
//         if( varSlope > 0.0 ) {
//         color = mix( color, vec4(0,0,0,1), varSlope * 50.0 );
//         }
//         if( varSlope < 0.0 ) {
//         color = mix( color, vec4(1,1,1,1), abs(varSlope) * 50.0 );
//         }
//         gl_FragColor = color;
//     }`;
// };

// vertex shaders
    // precision mediump float;
    // uniform float uTime;
    // uniform vec2 uFrequency;
    // varying vec2 vUv;

    // void main() {
    //     vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    //     modelPosition.z += sin(modelPosition.x * uFrequency.x + uTime) * 0.1;
    //     modelPosition.z += sin(modelPosition.y * uFrequency.y + uTime) * 0.05;
    //     vec4 viewPosition = viewMatrix * modelPosition;
    //     vec4 projectedPosition = projectionMatrix * viewPosition;
    //     vUv = uv;
    //     gl_Position = projectedPosition;
    // }

// fragment shaders
    // precision mediump float;
    // uniform sampler2D uTexture;
    // varying vec2 vUv;

    // void main() {
    //     vec4 textureColor = texture2D(uTexture,vUv);
    //      gl_FragColor = textureColor;
    // }

import { ShaderMaterial, Vector2 } from "three";
import { extend } from "@react-three/fiber";

class CustomMaterial extends ShaderMaterial {
        constructor() {
        super({
            vertexShader: 
            `
                precision mediump float;
                uniform float uTime;
                uniform vec2 uFrequency;
                varying vec2 vUv;

                void main() {
                    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
                    modelPosition.z += sin(modelPosition.x * uFrequency.x + uTime) * 0.1;
                    modelPosition.z += sin(modelPosition.y * uFrequency.y + uTime) * 0.05;
                    vec4 viewPosition = viewMatrix * modelPosition;
                    vec4 projectedPosition = projectionMatrix * viewPosition;
                    vUv = uv;
                    gl_Position = projectedPosition;
                }
            `,
            fragmentShader: 
            `
                precision mediump float;
                uniform sampler2D uTexture;
                varying vec2 vUv;

                void main() {
                    vec4 textureColor = texture2D(uTexture,vUv);
                    gl_FragColor = textureColor;
                }
            `,
            uniforms: {
                uTime: { value: 0.0 },
                uFrequency: { value: new Vector2(8, 4) },
                uTexture: { value: null }
            }
        });
    }

    set map(value) {
        // this.uniforms.hasTexture.value = !!value;
        this.uniforms.uTexture.value = value;
    }
};

extend({ CustomMaterial });