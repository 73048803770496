import React, { Suspense, useEffect } from "react";

import * as THREE from "three";
import { extend, useThree } from "@react-three/fiber";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import ChairMesh from "./chairMesh";

extend({OrbitControls});

export const Scene = ({newMaterialOpt}) => {
    const {
        scene, camera,
        gl: {domElement, shadowMap}
    } = useThree();

    // Scene configuration;
    useEffect(() => {
        const directionalLight = scene.children[1];
        
        camera.fov = 50;

        directionalLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
        
        shadowMap.enabled = true;
    }, []);

    return (
        <>
            <orbitControls args={[camera, domElement]}/>

            <hemisphereLight
                skycolor={new THREE.Color(0xffffff)}
                groundColor={new THREE.Color(0xffffff)}
                intensity={0.61}
                position={[0, 50, 0]}
            />

            <directionalLight
                color={new THREE.Color(0xffffff)}
                intensity={0.8}
                position={[-8, 12, 8]}
                castShadow
            />

            <Suspense fallback={null}>
                <ChairMesh newMaterialOpt={newMaterialOpt}/>
                
                <mesh
                    position={[0,-1,0]}
                    receiveShadow
                    rotation={[-0.5 * Math.PI, 0, 0]}
                >
                    <planeGeometry args={[8, 8, 1, 1]} />

                    <meshPhongMaterial
                        color="#dcdcdc" 
                        opacity={0.15} 
                        transparent
                        shininess={0}
                    />
                </mesh>
            </Suspense>
        </>
    );
};