import React, { useState, useEffect } from 'react';

import { Card, CardImg } from 'reactstrap';

import { useParams, useNavigate } from 'react-router-dom';

import { ref, listAll, getDownloadURL } from 'firebase/storage';

import { Canvas } from '@react-three/fiber';

// import { ARButton, XR } from '@react-three/xr';

import { storage } from '../firebase-config';
import './webAR.css';

export default function ViewARImg (){
    const [platform, setPlatform] = useState('');
    const [sModelObj, setSMObj] = useState('');
    const [sIATObj, setSITObj] = useState('');
    const [sIAObj, setSIObj] = useState('');

    const imgARTListRef = ref(storage, 'img_target/');
    const modelListRef = ref(storage, 'models_img/');
    const imgARListRef = ref(storage, 'images_ar/');

    const { name } = useParams();
    const navigate = useNavigate();

    const handleName = (names) => {
        let arr1 = names.split('/');
        let arr2 = arr1[1].split('.');
        return arr2[0];
    };

    const loadAll = () => {
        let arr = [];

        listAll(modelListRef).then((res) => {
            res.items.forEach((it) => {            
                getDownloadURL(it).then((url) => {
                    if(handleName(it._location.path) === name){
                        setSMObj(url);
                    };
                });
            });
        });

        listAll(imgARTListRef).then((res) => {
            res.items.forEach((it) => {          
                getDownloadURL(it).then((url) => {
                    if(handleName(it._location.path) === name){
                        // setSITObj([url, ...sIATObj]); 
                        arr.push(url);
                    };
                });
            });
        });

        listAll(imgARListRef).then((res) => {
            res.items.forEach((it) => {            
                getDownloadURL(it).then((url) => {
                    if(handleName(it._location.path) === name){
                        setSIObj(url);
                    };
                });
            });
        });

        console.log(arr); setSITObj(arr);
    };

    useEffect(() => {
        loadAll(); getOS();
        document.title = `View AR Image - ${name}`;
    
        // var cameraView;

        // var constraints = {
        //     audio: false,
        //     video: {
        //         facingMode: "environment",
        //     }
        // };

        // Access the device camera and stream to cameraView
        // function cameraStart() {
        //     cameraView = document.querySelector("#webcam");

        //     navigator.mediaDevices.getUserMedia(constraints)
        //     .then((stream) => {
        //         cameraView.srcObject = stream;
        //     }).catch((error) => {
        //         console.log("Oops. Something is broken.", error);
        //     });
        // };

        // Start the video stream when the window loads
        // window.addEventListener("load", cameraStart);
    }, []);

    const getOS = () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (/Linux/.test(platform)) {
          os = 'Linux';
        };

        setPlatform(os);
    };

    const checkOS = (val) => {
        switch(val){
            case 'Android':
            case 'iOS':
                return true;
            default:
                return false;
        };
    };

    return (
        <div>
            {(checkOS(platform)===true) ? <div>
                    {/* <video id="webcam" autoplay playsInline></video>

                    <a-scene
                        vr-mode-ui="enabled: false;"
                        renderer="logarithmicDepthBuffer: true;"
                        embedded
                        arjs="trackingMethod: best; sourceType: webcam;debugUIEnabled: false;"
                    >
                        <a-nft
                            type="nft"
                            url={sIATObj}
                            smooth="true"
                            smoothCount="10"
                            smoothTolerance=".01"
                            smoothThreshold="5"
                        >
                            <a-entity
                                gltf-model={sModelObj}
                                scale="1 1 1"
                                position="0 1 0"
                            ></a-entity>
                        </a-nft>

                        <a-entity camera look-controls></a-entity>
                    </a-scene> */}

                    {/* <ARCanvas
                        gl={{ antialias: false, powerPreference: "default", physicallyCorrectLights: true }}
                        onCameraStreamReady={() => console.log("Camera stream ready")}
                        onCameraStreamError={() => console.error("Camera stream error")}
                        onCreated={({ gl }) => {
                            gl.setSize(window.innerWidth, window.innerHeight)
                        }}
                    >
                        <ambientLight />

                        <pointLight position={[10, 10, 0]} intensity={10.0} />

                        <ARMarker
                            params={{ smooth: true }}
                            type={"pattern"}
                            patternUrl={sIATObj}
                            onMarkerFound={() => {
                                console.log("Marker Found")
                            }}
                        >
                            <mesh>
                                <boxGeometry />
                                <meshStandardMaterial color="hotpink" transparent />
                            </mesh>
                        </ARMarker>
                    </ARCanvas> */}
                    {/* <ARButton /> */}
                    
                    <Canvas>

                    </Canvas>
                </div> : <div style={{
                    display: 'flex', flexDirection: 'row', 
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Card style={{
                        boxShadow: '7px 6px 5px 0px rgba(204,204,204,0.75)',
                        width: '50vw', height: '85vh',
                    }}>
                        <div style={{
                            position: 'absolute', left: '98%'
                        }}>
                            <div style={{
                                backgroundColor: 'red', borderRadius: '100%', 
                                width: '30px', height: '30px', color: '#fff', 
                                textAlign: 'center', fontSize: '17px', fontWeight: '700' 
                            }} onClick={() => navigate('/WebXR')}>x</div>
                        </div>

                        <CardImg 
                            src={sIAObj} top 
                            style={{ 
                                height: '100%', width: '100%', padding: '5px',
                                borderRadius: '5px', cursor: 'pointer',
                            }}
                        />
                    </Card>
                </div>
            }
        </div>
    );
};