import React from 'react'
import { Canvas } from '@react-three/fiber'
import Experience from './experience.jsx'
import { KeyboardControls } from '@react-three/drei'
import Interface from './interface.jsx'
import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './index.css';

export default function Game1({show}){
    return(
        <div style={{height: '95vh'}}>
            <div style={{ color: 'oynxblack', width: '3vw', height: 'auto', position: 'fixed', zIndex: 100, padding: '2vw' }}>
                <FontAwesomeIcon 
                    icon={faLeftLong} onClick={() => show('')}
                />&nbsp;&nbsp;<span 
                    style={{fontSize: '18px'}} onClick={() => show('')}
                >Back</span>
            </div>
            
            <KeyboardControls map={[
                {name: 'forward', keys: ['ArrowUp', 'KeyW']},
                {name: 'backward', keys: ['ArrowDown', 'KeyS']},
                {name: 'leftward', keys: ['ArrowLeft', 'KeyA']},
                {name: 'rightward', keys: ['ArrowRight', 'KeyD']},
                {name: 'jump', keys: ['Space']},
            ]}>
                <Canvas
                    shadows
                    camera={{
                        fov: 45, near: 0.1,
                        far: 200, position: [2.5, 4, 6]
                    }}
                >
                    <Experience />
                </Canvas>

                <Interface />
            </KeyboardControls>
        </div>
    );
};