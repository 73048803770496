import React, { Suspense, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
// import { useControls } from 'leva';

import { Avatar } from './projectcomponents/avatar';

export default function Projects(){
    useEffect(() => {
        document.title = 'S.A.V Datla Portfolio Page';
    }, []);

    return( 
        <div className='projects'>
            <div style={{ height: '93vh', marginTop: '5px', backgroundColor: 'transparent' }}>
                <Canvas shadows={true} dpr={[1, 2]} camera={{fov: 75, near: 1, far: 1000, position: [0, 0, 2]}}>
                    <ambientLight intensity={0.5} />

                    <directionalLight color="white" position={[2, 5, 1]} />
                    
                    <OrbitControls />

                    <Suspense fallback={null}>
                        <Avatar position={[0, -1, 0.5]} />
                    </Suspense>
                </Canvas>
            </div>
        </div>
    )
};