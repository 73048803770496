import React, { useState } from 'react';

import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { ScrollPanel } from 'primereact/scrollpanel';

import ShoeConfig from './shoeConfig';
import ShirtConfig from './shirtConfig';
import ChairConfig from './chairConfig';
// import AvatarConfig from './avatarConfig';

import './Configurator.css';

const Configurator = () => {
    const [tab, setTab] = useState('shirt');
    const [tabIdx, setTdx] = useState(0);

    let tabArr = ['shirt', 'shoe', 'chair'];

    const handleLeft = (idx) => {
        if(idx>=0){
            setTdx(idx);
            setTab(tabArr[idx]);
        }else if(idx===0){
            setTdx(tabArr.length-1);
            setTab(tabArr[tabArr.length-1]);
        }
    };

    const handleRight = (idx) => {
        if(idx<=tabArr.length-1){
            setTdx(idx);
            setTab(tabArr[idx]);
        }else if(idx===(tabArr.length-1)){
            setTdx(0); setTab(tabArr[0]);
        }
    };

    return (
        <ScrollPanel style={{width: 'auto', height: '90vh', cursor: tab==='shoe' ? '' : 'pointer'}}>
            <h3 style={{textAlign: 'center', cursor: 'pointer'}}>Configurator's</h3>

            <Container>
                <Row>
                    <Col xs={12} style={{cursor: 'pointer'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <div onClick={() => handleLeft(tabIdx-1)}>
                                <FontAwesomeIcon icon={faAngleLeft} style={{fontSize: '25px', marginTop: '7.5px', marginRight: '2.5vw'}} />
                            </div>
                            
                            <div style={{fontSize: '20px', fontWeight: '700', marginTop: '5px'}}>
                                {tab[0].toUpperCase() + tab.slice(1)}
                            </div>
                            
                            <div onClick={() => handleRight(tabIdx+1)}>
                                <FontAwesomeIcon icon={faAngleRight} style={{fontSize: '25px', marginTop: '7.5px', marginLeft: '2.5vw'}} />
                            </div>
                        </div>
                    </Col>
                </Row>

                {tab==='shirt' && <Col xs={12}>
                    <ShirtConfig />
                </Col>}

                {tab==='shoe' && <Col xs={12}>
                    <ShoeConfig />
                </Col>}

                {tab==='chair' && <Col xs={12}>
                    <ChairConfig />
                </Col>}

                {/* {tab==='avatar' && <Col xs={12}>
                    <AvatarConfig />
                </Col>} */}
            </Container>
        </ScrollPanel>
    );
};

export default Configurator;