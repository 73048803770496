import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAuth } from  'firebase/auth';

const firebaseConfig  = {
    apiKey: "AIzaSyBQJ3D22gIDyzFHi_xJoGMhLc0ylMCXvp0",
    authDomain: "portfolio-2d2aa.firebaseapp.com",
    projectId: "portfolio-2d2aa",
    storageBucket: "portfolio-2d2aa.appspot.com",
    messagingSenderId: "605508864160",
    appId: "1:605508864160:web:c71bbda7599bcce190a1c5",
    measurementId: "G-7WMZNR98RS",
    databaseURL: "https://portfolio-2d2aa-default-rtdb.firebaseio.com/",
};

const app = initializeApp(firebaseConfig);

export const openAi_API = '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';
export const openai_api = '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';

export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const realDB = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth(app);