import React, { useState } from "react";

import * as THREE from "three";
import { Canvas } from "@react-three/fiber";

import "./index.css";

import { Scene } from "./canvs";
import ColorSlider from "./colorSlider";
import { COLORS, OptionsMenu } from "./stores";

const ChairConfig = () => {
    const [activeOption, setActiveOption] = useState("legs");

    const [newMaterialOpt, setNewMaterialOpt] = useState({
        activeOption,
        newMTL: null
    });

    const selectSwatch = (e) => {
        let color = COLORS[parseInt(e.target.dataset.key)], newMTL;

        if (color.texture) {
            let txt = new THREE.TextureLoader().load(color.texture);

            txt.repeat.set(color.size[0], color.size[1], color.size[2]);
            txt.wrapS = THREE.RepeatWrapping;
            txt.wrapT = THREE.RepeatWrapping;

            newMTL = new THREE.MeshPhongMaterial({
                map: txt,
                shininess: color.shininess ? color.shininess : 10
            });
        } else {
            newMTL = new THREE.MeshPhongMaterial({
                color: parseInt("0x" + color.color),
                shininess: color.shininess ? color.shininess : 10
            });
        }

        return setNewMaterialOpt({
            activeOption, newMTL
        });
    };

    return (
        <React.Fragment>
            <OptionsMenu
                activeOption={activeOption}
                setActiveOption={setActiveOption}
            />

            <Canvas style={{width: 'auto', height: '65vh'}}>
                <Scene newMaterialOpt={newMaterialOpt} />
            </Canvas>

            <div className="controls">
                <ColorSlider selectSwatch={selectSwatch} />
            </div>
        </React.Fragment>
    );
};

export default ChairConfig;