import React, { useEffect, useState } from 'react';

import { Container, Row, Col } from 'reactstrap';

import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { collection, getDocs, 
    // doc, updateDoc 
} from 'firebase/firestore';

import QRCode from 'react-qr-code';

// import { Vortex } from  'react-loader-spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faCopy, faClone } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
// import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';

import { db, storage } from '../firebase-config';

// import LikeIcon from '../assets/like.png';
// import LikedIcon from '../assets/liked.png';

const imgListRef = ref(storage, 'images/');
const modelListRef = ref(storage, 'models/');

// const imgARListRef = ref(storage, 'images_ar/');
// const imgARTListRef = ref(storage, 'img_target/');
// const modelImgListRef = ref(storage, 'models_img/');

export default function WebAR(){
    const [imgs, setImgs] = useState('');
    const [models, setMdls] = useState('');
    const [arData, setARData] = useState('');
    // const [imgAr, setImgAr] = useState('');
    // const [modelAr, setMdlAr] = useState('');
    const [selObj, setSObj] = useState('');
    const [open, setOpen] = useState(false);
    const [platform, setPlatform] = useState('');
    const [view, setView] = useState('');
    // const [popup, setPopup] = useState(false);
    const [copy, setCopy] = useState(false);
    // const [isLike, setLiked] = useState([]);
    const [isCopy, setCpy] = useState(false);

    // const [tab, setTab] = useState('surface');

    const handleName = (names) => {
        let arr1 = names.split('/');
        let arr2 = arr1[1].split('.');

        return arr2[0];
    };

    const handleAR = (obj) => {
        setView(window.location.origin+"/ViewAr/"+obj.name);

        for(let i=0; i<models.length; i++){
            if(obj.name===models[i].name){
                obj.model = models[i].model
            };
        };

        setSObj(obj); setOpen(true);
    };

    const getOS = () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (/Linux/.test(platform)) {
          os = 'Linux';
        };

        setPlatform(os);
    };

    // const loadARData = () => {
    //     if(imgAr.length===0){
    //         listAll(imgARListRef).then((res) => {
    //             res.items.forEach((it) => {            
    //                 getDownloadURL(it).then((url) => {
    //                     console.log(it._location.path)
    //                     setImgAr((prev) => [...prev, {id: v4(), name: handleName(it._location.path), path: url, type: 'image'}]);
    //                     // iArr1.push({id: v4(), name: handleName(it._location.path), path: url, type: 'image'});
    //                 });
    //             });
    //         });
    //     };

    //     if(modelAr.length===0){
    //         listAll(modelImgListRef).then((res) => {
    //             res.items.forEach((it) => {            
    //                 getDownloadURL(it).then((url) => {
    //                     setMdlAr((prev) => [...prev, {id: v4(), name: handleName(it._location.path), path: url, type: 'image'}]); 
    //                 });
    //             });
    //         }); 
    //     };
    // };

    const handleViewName = (name) => {

        for(let i=0; i<arData.length; i++){
            if(name===arData[i].file_name){
                return arData[i].name;
            }
        }
    };

    const loadData = () => {
        listAll(imgListRef).then((res) => {
            res.items.forEach((it) => {            
                getDownloadURL(it).then((url) => {
                    setImgs((prev) => [...prev, 
                        {name: handleName(it._location.path), img: url, type: 'surface'}
                    ]);
                });
            });
        });
    
        listAll(modelListRef).then((res) => {
            res.items.forEach((it) => {            
                getDownloadURL(it).then((url) => {
                    setMdls((prev) => [...prev, 
                        {name: handleName(it._location.path), model: url, type: 'surface'}
                    ]); 
                });
            });
        }); 
    
        getDocs(
            collection(db, "armodule")
        ).then((res) => {
            let data = [];
             
            res.forEach((doc) => {
                data.push(doc.data()); 
            });

            setARData(data);
        }).catch((err) => {
            console.log(err);
        });
    };

    // const loadData = async () => {
    //     const command = new ListObjectsV2Command({
    //         Bucket: "ar-image-model",
    //         // The default and maximum number of keys returned is 1000. This limits it to
    //         // one for demonstration purposes.
    //         MaxKeys: 10,
    //     });
        
    //     try {
    //         let isTruncated = true, contents = "";
        
    //         while (isTruncated) {
    //             const { 
    //                 Contents, IsTruncated, 
    //                 NextContinuationToken 
    //             } = await client.send(command);

    //             const contentsList = Contents.map((c) => ` • ${c.Key}`).join("\n");

    //             contents += contentsList + "\n";

    //             isTruncated = IsTruncated;

    //             command.input.ContinuationToken = NextContinuationToken;
    //         };

    //         console.log(contents);

    //     } catch (err) {
    //         console.log(err);
    //     };

    //     const command2 = new ListObjectsV2Command({
    //         Bucket: "ar-image-imgs",
    //         // The default and maximum number of keys returned is 1000. This limits it to
    //         // one for demonstration purposes.
    //         MaxKeys: 10,
    //     });
        
    //     try {
    //         let isTruncated2 = true, contents2 = "";
        
    //         while (isTruncated2) {
    //             const { 
    //                 Contents, IsTruncated, 
    //                 NextContinuationToken 
    //             } = await client.send(command2);

    //             const contentsList = Contents.map((c) => ` • ${c.Key}`).join("\n");

    //             contents2 += contentsList + "\n";

    //             isTruncated2 = IsTruncated;

    //             command2.input.ContinuationToken = NextContinuationToken;
    //         };

    //         console.log(contents2);

    //     } catch (err) {
    //         console.log(err);
    //     };
    // };

    // const loadARData = async () => {
    //     const command = new ListObjectsV2Command({
    //         Bucket: "ar-surface-model",
    //         // The default and maximum number of keys returned is 1000. This limits it to
    //         // one for demonstration purposes.
    //         MaxKeys: 10,
    //     });
        
    //     try {
    //         let isTruncated = true, contents = "";
        
    //         while (isTruncated) {
    //             const { 
    //                 Contents, IsTruncated, 
    //                 NextContinuationToken 
    //             } = await client.send(command);

    //             const contentsList = Contents.map((c) => ` • ${c.Key}`).join("\n");

    //             contents += contentsList + "\n";

    //             isTruncated = IsTruncated;

    //             command.input.ContinuationToken = NextContinuationToken;
    //         };

    //         console.log(contents);

    //     } catch (err) {
    //         console.log(err);
    //     };

    //     const command2 = new ListObjectsV2Command({
    //         Bucket: "ar-surface-imgs",
    //         // The default and maximum number of keys returned is 1000. This limits it to
    //         // one for demonstration purposes.
    //         MaxKeys: 10,
    //     });
        
    //     try {
    //         let isTruncated2 = true, contents2 = "";
        
    //         while (isTruncated2) {
    //             const { 
    //                 Contents, IsTruncated, 
    //                 NextContinuationToken 
    //             } = await client.send(command2);

    //             const contentsList = Contents.map((c) => ` • ${c.Key}`).join("\n");

    //             contents2 += contentsList + "\n";

    //             isTruncated2 = IsTruncated;

    //             command2.input.ContinuationToken = NextContinuationToken;
    //         };

    //         console.log(contents2);

    //     } catch (err) {
    //         console.log(err);
    //     };
    // };

    const handleClose = () => {
        setOpen(false); setSObj('');
    };

    const handleCopy = (obj) => {
        setCopy(true); setSObj(obj);
        
        // if(obj.type==='surface'){
            setView(window.location.origin+"/ViewAr/"+obj.name);
        // }else if(obj.type==='image'){
        //     setView(window.location.origin+"/ViewArImg/"+obj.name);
        // }
    };

    const copyToClipboard = (text) => {
        var textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCpy(true);
    };

    useEffect(() => {
        getOS(); loadData(); 
        // loadARData();
        document.title = 'WebXR - AR & VR';
    }, []);

    const checkOS = (val) => {
        switch(val){
            case 'Android' :
            case 'iOS' :
                return true;
            default:
                return false;       
        };
    };

    // const handleLike = (val) => {
    //     const req = doc(db, 'armodule', val.name);

    //     if(val.like===false){
    //         updateDoc(req, {
    //             likes: val.likes+1
    //         });

    //         return val.like = true, val.likes+=1; 
    //     }else{
    //         updateDoc(req, {
    //             likes: val.likes-1
    //         });

    //         return val.like = false, val.likes-=1; 
    //     };
    //     // const q = query(collection(db, "requests"), where("name", "==", val.name));

    //     // const querySnapshot = getDocs(q);

    //     // let docID = '';

    //     // querySnapshot.forEach((docss) => {
    //     //     docID = docss.id;
    //     // });

    //     // const request = doc(db, "requests", docID);

    //     // console.log('Test: ', val, request);

    //     // if(like===true){
    //     //     await updateDoc(request, {
    //     //         like: val.likes+1
    //     //     });
    //     // }else if(like===false){
    //     //     await updateDoc(request, {
    //     //         like: val.likes-1
    //     //     });
    //     // };
    // };

    return(
        <React.Fragment>
                <div style={{marginTop: '1%', height: 'auto', width: '100%'}}>
                    <div>
                        <h4 style={{textAlign: 'center', cursor: 'default'}}>Augmented Reality - (Surface Target)<sub style={{fontSize: '15px'}}>&nbsp;&nbsp;in Web</sub></h4>
                        
                        <ScrollPanel style={{ width: '100%', height: '82.1vh' }}>
                            <Container>
                                <Row>
                                    {imgs && imgs.map((data, idx) => <Col xs={12} sm={6} md={4} lg={3} key={idx}>
                                        <div style={{boxShadow: '7px 6px 5px 0px rgba(204,204,204,0.75)', height: '30vh', width: 'auto', border: '2px solid', borderRadius: '5px'}}>
                                            <div 
                                                style={{textAlign: 'center', padding: '3px', fontWeight: '600'}} 
                                            >
                                                {handleViewName(data.name)}
                                            </div>

                                            <img 
                                                src={data.img} top onClick={() => {handleAR(data)}} 
                                                style={{ height: '20vh', width: '100%', cursor: 'pointer', borderTop: '1px solid', borderBottom: '1px solid' }}
                                            />
                                            
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2px'}}>                     
                                                {/* <div>
                                                    {data.like===false 
                                                        ? <img 
                                                            src={LikeIcon} onClick={() => handleLike(data)}
                                                            style={{width: '18px', height: '16px'}}
                                                        /> : <img 
                                                            src={LikedIcon} onClick={() => handleLike(data)}
                                                            style={{width: '18px', height: '16px'}}
                                                        />
                                                    }
                                                    <span style={{fontWeight: '700', marginLeft: '5px'}}>{data.likes}</span>
                                                </div> */}

                                                <div style={{color: '#228b22'}}>
                                                    <FontAwesomeIcon 
                                                        icon={faShare} onClick={() => handleCopy(data)} 
                                                        style={{fontSize: '18px', marginLeft: '7.5px'}} 
                                                    />
                                                    &nbsp;&nbsp;<span>Share</span>
                                                </div>  
                                            </div>
                                        </div>
                                    </Col>)}
                                </Row>
                            </Container>  
                        </ScrollPanel>  
                    </div>
                </div>
            {/* </BlockUI> */}

            {/* WebAR Model */}
            <Dialog 
                visible={open} onHide={handleClose} 
                header={<div>AR View ({selObj.ar_type === 'surface' ? 'Surface Target' : 'Image Target'}) - {handleViewName(selObj.name)}</div>}
                style={{width: '75vw', height: 'auto'}}
            >
                <Container>
                    <Row>
                        {/* {selObj.type==='surface' ?  */}
                        <Col xs={12} sm={8}>
                            <model-viewer
                                autoplay 
                                ar
                                ar-modes="webxr scene-viewer quick-look"
                                src={selObj.model} 
                                poster={selObj.img}
                                loading="eager"
                                shadow-intensity="1"
                                auto-rotate 
                                camera-controls
                                style={{
                                    width: 'auto', height: '42vh',
                                    marginLeft: '2%', background: "#F5F5F5",
                                    border: "3px solid #808080", borderRadius: "5px",
                                    boxShadow: '7px 6px 5px 0px rgba(204,204,204,0.75)',
                                }}
                            ></model-viewer>
                        </Col>
                        {/* : */}
                         {/* <>
                            <Col xs={12} sm={5}>
                                <model-viewer
                                    autoplay 
                                    ar-modes="scene-viewer quick-look"
                                    src={selObj.model} 
                                    shadow-intensity="1"
                                    auto-rotate
                                    camera-controls
                                    style={{
                                        width: 'auto', height: '42vh',
                                        marginLeft: '2%', background: "#F5F5F5",
                                        border: "3px solid #808080", borderRadius: "5px",
                                        boxShadow: '7px 6px 5px 0px rgba(204,204,204,0.75)',
                                    }}
                                ></model-viewer>
                            </Col>

                            <Col xs={12} sm={2} style={{ textAlign: 'center' }}>
                                <Image 
                                    src={selObj.path} alt={selObj.name} width='250' 
                                    style={{ height: 'auto', marginTop: '8px' }} preview 
                                />
                            </Col>
                        </>} */}

                        {(checkOS(platform)===false) && <Col xs={12} sm={4}>
                            <div style={{textAlign: 'center', marginTop: '8vh'}}>
                                <QRCode size={128} value={view} />
                            </div>
                        </Col>}
                        
                        {/* <Col xs={12} sm={12}>
                            <ul style={{marginTop: '0.5%'}}>
                                <li>After 3D Model loaded, Click on bottom right button. </li>
                                <li>Grab your device (Android or IOS) and Point your device on Floor or Plane Surface. </li>
                                <li>After Floor or Plane Surface detected, Then 3D Model loaded and placed on detected Floor or Plane Surface</li>
                                <li>Enjoy AR experience.</li>
                            </ul>
                        </Col> */}
                    </Row>
                </Container>
            </Dialog>

            {/* Copy link Model */}
            <Dialog 
                visible={copy} style={{width: '55vw', height: 'auto'}}
                header={<div style={{textAlign: 'center'}}>Share Experience - {selObj.name}</div>}
                onHide={() => {setCopy(false); setSObj(''); setCpy(false)}}
            >
                <div className="p-inputgroup">
                    <InputText value={view} style={{textAlign: 'center'}} disabled />

                    <Button 
                        label={isCopy===false ? <FontAwesomeIcon icon={faCopy} /> : <FontAwesomeIcon icon={faClone} />} 
                        style={{marginTop: '-4px', height: '35px'}} 
                        onClick={() => copyToClipboard(view)}
                    />
                </div>
            </Dialog>
        </React.Fragment>
    );
};