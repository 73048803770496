import { proxy } from 'valtio'

const state = proxy({
  intro: true,
  colors: [
    '#7cfc00', '#587246', '#9acd32', 
    '#7FFFD4', '#36454f', '#5D8AA8', 
    '#ff4500', '#cc5500', 
    '#EFBD4E','#ffb347',
    '#dcdcdc', '#efdecd','#8f8b66', '#BDB76B',
    '#353934',
    
  ],
  decals: ['react', 'three', 'firebase', 'etherium'],
  color: '#353934',
  decal: 'react'
})

export { state };