import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DataContext from './dataContext';

//for firebase storage cors err -> gsutil cors set cors.json gs://portfolio-2d2aa.appspot.com

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <DataContext>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </DataContext>
);

reportWebVitals();