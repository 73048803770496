import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUser } from '@fortawesome/free-solid-svg-icons';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';

// import { ConnectWallet, useAddress, ThirdwebProvider } from "@thirdweb-dev/react";

import Google from '../assets/logos/g-logo.png';
// import Facebook  from '../assets/logos/f-logo.png';

import { db } from '../firebase-config';

const Profile = () => {

    const [info, setInfo] = useState('');

    // const address = useAddress();

    const [imgs, setImg] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [sign, setSign] = useState('');
    const [crypto, setCrypto] = useState('');

    // useEffect(() => {
    //     if(info && !info.crypto_sign_in && address){
    //         setCrypto(address);
    //     };
    // }, [address]);

    const handleSubmit = async () => {
        const q = query(collection(db, "users"), where("Email", "==", info.email));

        const querySnapshot = await getDocs(q);

        let docID = '';

        querySnapshot.forEach((docss) => {
            docID = docss.id;
        });

        const request = doc(db, "users", docID);

        await updateDoc(request, {
            name: name,
            email: email,

        });

    };

    useEffect(() => {
        if( JSON.parse(localStorage.getItem('user')) ){
            let user = JSON.parse(localStorage.getItem('user'));
            document.title = `${JSON.parse(localStorage.getItem('user')).name} profile`;
    
            setCrypto(user.crypto_sign_in); setEmail(user.email); setImg(user.img);
            setInfo(user); setName(user.name); setSign(user.sign_in);
        }else{
            window.location.reload('/');
        }
    }, []);

  return (
    <div style={{height: '100%', width: '100%', paddingBottom: '40vh', marginTop: '4.4vh'}}>
        <Container style={{display: 'flex', justifyContent: 'space-evenly', alignContent: 'center'}} fluid>
            <Row>
                <Col xs={12} sm={3}>
                    {imgs ? <div>
                        <img 
                            src={imgs} 
                            style={{ 
                                border: '3px solid rgb(95, 158, 160)', 
                                padding: '1%', borderRadius: '70%', 
                                width: '100px', height: '100px',
                            }}
                        /> </div> : <FontAwesomeIcon 
                        icon={faUser} 
                        style={{ 
                            border: '2px solid rgb(95, 158, 160)', 
                            padding: '3%', borderRadius: '20%', 
                            width: '45px', height: '45px',
                        }} />
                    }
                </Col>
            </Row>

            <Row style={{marginLeft: '5%'}}>
                <Col xs={12} sm={6}>
                    <h5>Name</h5>
                    <InputText value={name} onChange={(e) => setName(e.target.value)} style={{width: '100%'}} />
                </Col>

                <Col xs={12} sm={6}>
                    <h5>Email</h5>
                    <InputText value={email} onChange={(e) => setEmail(e.target.value)} style={{width: '100%'}} />
                </Col>

                <Col xs={12} sm={6}>
                    <h5 style={{marginTop: '5%'}}>Sign in - 
                        {sign==='google' ? <img 
                            src={Google} 
                            style={{width: '25px', height: '25px', borderRadius: '100%', marginLeft: '1%'}} 
                        /> : <span style={{marginLeft: '1%'}}>Admin</span>} 
                    </h5>
                </Col>

                <Col xs={12} sm={6}>
                    <h5 style={{marginTop: '5%'}}>Crypto Sign in - 
                        {crypto ? 
                            <FontAwesomeIcon 
                                icon={faCheck} 
                                style={{color: 'green', fontSize: '22px', marginLeft: 5}} 
                            /> : 'Connect Wallet'
                        }
                    </h5>
                </Col>
            </Row>
        </Container>
        
        <div style={{marginTop: '7%', textAlign: 'center'}}>
            <Button label='Submit' onClick={() => handleSubmit()} style={{width: '250px', backgroundColor: '#20B2AA', border: '0px solid'}} /> 
        </div>
    </div>
  );
};

export default Profile;