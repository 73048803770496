import React, { useState, useRef, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';

import JSConfetti from 'js-confetti';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { 
    signInWithPopup, GoogleAuthProvider, signOut,
    // FacebookAuthProvider, 
    signInWithEmailAndPassword, 
} from 'firebase/auth';

import { auth, db } from '../firebase-config';
import { UserState } from '../dataContext';

import SunLogo from '../assets/logos/sun.png';
import MoonLogo from '../assets/logos/moon.png';
// import fLogo from '../assets/logos/f-logo.png';
import Glogo from '../assets/logos/g-logo.png';
// import Footer from './footer';
import '../App.css';

export default function Header(){
    const { user, setUser, mode, setMode } = UserState();

    const userCollection = collection(db, 'users');

    const navigate = useNavigate();
    
    const [open, setOpen] = useState(false);
    const [arr, setArr] = useState([]);

    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    const profile = useRef(null);
    const confettiRef = useRef();

    useEffect(() => {
        confettiRef.current = new JSConfetti();

        getDocs(
            collection(db, "users")
        ).then((res) => {
            let data = [];

            res.forEach((doc) => {
                data.push({...doc.data(), uid: doc.id});
            });

            setArr(data);

            if(localStorage.getItem('user')){
                let obj = JSON.parse(localStorage.getItem('user'));
                for(let i=0; i<=data.length; i++){
                    if((obj && obj.id) === data[i].id){
                        setUser(data[i]); 
                    }
                };
            };
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const items = [
        {
            label: `${user ? user.name : 'login'}`,
            items: user && user.sign_in==='admin' ? [
                {
                    label: 'Editor',
                    icon: 'pi pi-box',
                    command: () => { navigate('/Editor') }
                },
                {
                    label: 'Web XR',
                    icon: 'pi pi-qrcode',
                    command: () => { navigate('/WebXR') }
                },
                {
                    label: 'Games',
                    icon: 'pi pi-ticket',
                    command: () => { navigate('/Games') }
                },
                {
                    label: 'Dashboard',
                    icon: 'pi pi-book',
                    command: () => { navigate('/Dashboard') }
                },
                {
                    label: 'Profile',
                    icon: 'pi pi-user',
                    command: () => { navigate('/Profile') }
                },
                {
                    label: 'Logout',
                    icon: 'pi pi-sign-out',
                    command: () => { logout() }
                   
                }
            ] : [
                {
                    label: 'Web XR',
                    icon: 'pi pi-qrcode',
                    command: () => { navigate('/WebXR') }
                },
                {
                    label: 'Games',
                    icon: 'pi pi-ticket',
                    command: () => { navigate('/Games') }
                },
                {
                    label: 'Profile',
                    icon: 'pi pi-user',
                    command: () => { navigate('/Profile') }
                },
                {
                    label: 'Logout',
                    icon: 'pi pi-sign-out',
                    command: () => { logout() }
                   
                }
            ]
        },
    ];

    const google = new GoogleAuthProvider();
    // const facebook = new FacebookAuthProvider();

    const gLogin = async () => {
        signInWithPopup(auth, google).then((res) => {
            const user = res.user;
        
            let selobj = arr.filter((it) =>  it.id === res.user.uid);

            if(selobj.length===0){
                addDoc(
                    userCollection, 
                    {
                        name: user.displayName, 
                        email: user.email, 
                        img: user.photoURL, 
                        sign_in: 'google', 
                        crypto_sign_in: '', 
                        id: user.uid, 
                        uid: '',
                    }
                ).then((res) => {
                    updateDoc(doc(db, res._key.path.segments[0], res._key.path.segments[1]), {
                        uid: res._key.path.segments[1]
                    });

                    setUser({
                        name: user.displayName, 
                        email: user.email, 
                        img: user.photoURL, 
                        sign_in: 'google', 
                        crypto_sign_in: '', 
                        id: user.uid, 
                        uid: res._key.path.segments[1],
                    }); 

                    confettiRef.current.addConfetti({
                        confettiRadius: 5,
                        confettiNumber: 500,
                    });
                    
                    setOpen(false);
                    
                    localStorage.setItem('user', JSON.stringify({
                        name: user.displayName, 
                        email: user.email, 
                        img: user.photoURL, 
                        sign_in: 'google', 
                        crypto_sign_in: '', 
                        id: user.uid, 
                        uid: res._key.path.segments[1],
                    }));
                }).catch((err) => {
                    console.log(err);
                });            
            }else{
                setUser(selobj[0]); setOpen(false);
                localStorage.setItem('user', JSON.stringify(selobj[0]));
            }; 
        }).catch((err) => {
            const errorCode = err.code, 
            errorMessage = err.message, email = err.customData.email, 
            credential = GoogleAuthProvider.credentialFromError(err);
            console.log(errorCode, errorMessage, credential, email);
        });
    };

    // const fLogin = () => {
    //     signInWithPopup(auth, facebook).then((res) => {
    //         const credential = FacebookAuthProvider.credentialFromResult(res);
    //         console.log('user facebook: ', res, res.user.photoURL, res.user.displayName, credential);
    //         setUser(res.user); setOpen(false); localStorage.setItem('userData', JSON.stringify(res.user));
    //         // addDoc(userCollection, {name: res.user.displayName, email: res.user.email, img: res.user.photoURL, 'sign-in': 'facebook', 'crypto_sign-in': []});
    //     }).catch((err) => {
    //         const errorCode = err.code, 
    //         errorMessage = err.message, email = err.customData.email, 
    //         credential = FacebookAuthProvider.credentialFromError(err);
    //         console.log(errorCode, errorMessage, credential, email);
    //     });
    // };

    const logout = () => {
        signOut(auth).then((res) => {
            setUser(null); localStorage.removeItem('user');
            navigate('/');
        }).catch((err) => {
            console.log(err);
        });
    };

    const emailPasswordLogin = async () => {
        signInWithEmailAndPassword(auth, email, pass)
        .then((res) => {
            let selobj = arr.filter((it) =>  it.id === res.user.uid);
            setUser(selobj[0]); setOpen(false);
            localStorage.setItem('user', JSON.stringify(selobj[0]));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return(
        <React.Fragment>
            <header className='h-bar' style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{position: 'absolute', left: '1%'}}>
                    <a style={{color: '#282c34', border: '3px solid #5F9EA0', borderRadius: '100%', marginTop: '-1vh'}} onClick={() => navigate('/')}>
                        <FontAwesomeIcon icon={faHome} style={mode!==true ? {fontSize: '22px', padding: '8px'} : {fontSize: '22px', padding: '8px', color: '#f5f5f5'}} />
                    </a>
                </div>

                <div style={{position: 'absolute', right: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div onClick={() => setMode(!mode)} style={{marginRight: '15px'}}>
                        {mode===true ? <img src={SunLogo} className='clk_logo App-logo' /> : <img src={MoonLogo} className='clk_logo App-logo' />}
                    </div>
                
                    <div style={{marginLeft: '15px'}}>
                        {user===null ? <div style={{ color: '#282c34' }} onClick={() => setOpen(true) }>
                            <FontAwesomeIcon icon={faUserPlus}  style={mode!==true ? {fontSize: '22px', padding: '8px'} : {fontSize: '22px', padding: '8px', color: '#f5f5f5'}} />
                        </div> : <div style={{ color: '#282c34' }} onClick={(e) => profile.current.toggle(e)} aria-controls="popup_menu" aria-haspopup>
                            <img src={user.img} style={{ width: '55px', height: '55px', borderRadius: '75%', border: '2px solid #5F9EA0', padding: '3px' }} />
                        </div>}
                    </div>
                </div>

                <Menu model={items} popup ref={profile} id="popup_menu" />
            </header>

            <Dialog 
                visible={open} style={{width: '25vw'}} draggable={false}
                header={<div style={{textAlign: 'center'}}>User Login</div>}
                onHide={() => setOpen(false)}
            >               
                <Button 
                    icon={<div className='btnLogo'><img src={Glogo} style={{width: '30px', height: '30px'}} /></div>} 
                    label={<span className='btnLabel'>Google</span>} style={{ width: '100%' }} 
                    className="p-button-raised p-button-info" onClick={gLogin} 
                />

                {/* <Button 
                    icon={<div className='btnLogo'><img src={fLogo} style={{width: '30px', height: '30px'}} /></div>} 
                    label={<span className='btnLabel'>Facebook</span>} style={{ width: '100%', marginTop: '3.5vh' }} 
                    className="p-button-raised p-button-primary" onClick={fLogin} 
                /> */}

                <div style={{textAlign: 'center'}}>
                    <Divider align="center">
                        <b>Admin</b>
                    </Divider>

                    <InputText value={email} onChange={(e) => setEmail(e.target.value)} style={{ marginTop: '-1vh' }} />

                    <Password value={pass} onChange={(e) => setPass(e.target.value)} toggleMask style={{ marginTop: '2vh' }} />

                    <Button label='Submit' style={{ width: '100%', backgroundColor: 'limegreen', marginTop: '1.5vh', border: '0px solid' }} onClick={emailPasswordLogin} />
                </div>
            </Dialog>
        </React.Fragment>
    );
};