import { useAnimations, useFBX, useGLTF } from "@react-three/drei";
import { useControls } from "leva";
import React, { useEffect, useRef } from "react";

export function Avatar(props) {
  const group = useRef();

  const noneModel = useGLTF("/avatar_main.glb");
  const sitModel = useFBX('animations/sittingPose.fbx');
  const standModel = useFBX('animations/Stand.fbx');
  const handShakeModel = useFBX('animations/handShake.fbx');

  const value = useControls({
    animation: { 
      options: { 
        'sitting' : 'sitting', 
        'standing' : 'standing',
        'handShake' : 'handShake',
        'none': '',
      } 
    },
  });

  const { animations: MaleSittingPoseAnimation } = useFBX(
    "animations/sittingPose.fbx"
  );
  
  const { animations: StandAnimation } = useFBX(
    "animations/standUp.fbx"
  );

  const { animations: HandShakeAnimation } = useFBX(
    "animations/handShake.fbx"
  );

  // group.current.getObjectByName("Head").lookAt(state.camera.position);

  // const target = new THREE.Vector3(state.mouse.x, state.mouse.y, 1);
  // group.current.getObjectByName("Spine2").lookAt(target);

  // useEffect(() => {
  //   Object.values(materials).forEach((material) => {
  //     material.wireframe = true;
  //   });
  // }, []);

  StandAnimation[0].name = "standing"; 
  HandShakeAnimation[0].name = "handShake";
  MaleSittingPoseAnimation[0].name = "sitting"; 

  const avatarAnimations = useAnimations(
    [MaleSittingPoseAnimation[0], StandAnimation[0], HandShakeAnimation[0]], group
  );

  useEffect(() => {
    console.log('animation :', value.animation, avatarAnimations.actions[value.animation]);

    if(value.animation!=='') {
      avatarAnimations.actions[value.animation].reset().fadeIn(0.5).play();
    }

    return () => {
      avatarAnimations.actions[value.animation].reset().fadeOut(0.5);
    };
  }, [value.animation]);

  return (
    <group {...props} ref={group} dispose={null}>
      {value.animation==='' && <primitive object={noneModel} />}
      {value.animation==='sitting' && <primitive object={sitModel}  />}
      {value.animation==='standing' && <primitive object={standModel} />}
      {value.animation==='handShake' && <primitive object={handShakeModel} />}
    </group>
  );
}

useGLTF.preload("/avatar_main.glb");
useFBX.preload('animations/sittingPose.fbx');
useFBX.preload('animations/Stand.fbx');
useFBX.preload('animations/handShake.fbx');