import React from "react";

const options = [
    {
        name: "legs",
        img:"legs.svg"
    },
    {
        name:"cushions",
        img: "cushions.svg"
    },
    {
        name:"base",
        img:"base.svg"
    },
    {
        name:"supports",
        img: "supports.svg"
    },
    {
        name:"back",
        img: "back.svg"
    },
];

export const OptionsMenu = ({activeOption, setActiveOption}) => {
    return(
        <div className="options">
            {options.map(({name, img}) => (
                <div
                    className={`option ${activeOption === name ? "--is-active" : ""}`}
                    data-option={name}
                    onClick={() => setActiveOption(name)}
                    key={name}
                >
                    <img src={img} alt={name} />
                </div>
            ))}
        </div>
    )
};

export const COLORS = [
    {
        texture: 'img/wood_.jpg',
        size: [2, 2, 2],
        shininess: 60
    },

    {
        texture: 'img/fabric_.jpg',
        size: [4, 4, 4],
        shininess: 0
    },

    {
        texture: 'img/pattern_.jpg',
        size: [8, 8, 8],
        shininess: 10
    },

    {
        texture: 'img/denim_.jpg',
        size: [3, 3, 3],
        shininess: 0
    },

    {
        texture: 'img/quilt_.jpg',
        size: [6, 6, 6],
        shininess: 0
    },

    {
        color: '131417'
    },

    {
        color: '374047'
    },

    {
        color: '5f6e78'
    },

    {
        color: '7f8a93'
    },

    {
        color: '97a1a7'
    },

    {
        color: 'acb4b9'
    },

    {
        color: 'DF9998'
    },

    {
        color: '7C6862'
    },

    {
        color: 'A3AB84'
    },

    {
        color: 'D6CCB1'
    },

    // {
    //     color: 'F8D5C4'
    // },

    // {
    //     color: 'A3AE99'
    // },

    // {
    //     color: 'EFF2F2'
    // },

    // {
    //     color: 'B0C5C1'
    // },

    // {
    //     color: '8B8C8C'
    // },

    // {
    //     color: '565F59'
    // },

    // {
    //     color: 'CB304A'
    // },

    // {
    //     color: 'FED7C8'
    // },

    // {
    //     color: 'C7BDBD'
    // },

    // {
    //     color: '3DCBBE'
    // },

    // {
    //     color: '264B4F'
    // },

    // {
    //     color: '389389'
    // },

    // {
    //     color: '85BEAE'
    // },

    // {
    //     color: 'F2DABA'
    // },

    // {
    //     color: 'F2A97F'
    // },

    // {
    //     color: 'D85F52'
    // },

    // {
    //     color: 'D92E37'
    // },

    // {
    //     color: 'FC9736'
    // },

    // {
    //     color: 'F7BD69'
    // },

    // {
    //     color: 'A4D09C'
    // },

    // {
    //     color: '4C8A67'
    // },

    // {
    //     color: '25608A'
    // },

    // {
    //     color: '75C8C6'
    // },

    // {
    //     color: 'F5E4B7'
    // },

    // {
    //     color: 'E69041'
    // },

    // {
    //     color: 'E56013'
    // },

    // {
    //     color: '11101D'
    // },

    // {
    //     color: '630609'
    // },

    // {
    //     color: 'C9240E'
    // },

    // {
    //     color: 'EC4B17'
    // },

    // {
    //     color: '281A1C'
    // },

    // {
    //     color: '4F556F'
    // },

    // {
    //     color: '64739B'
    // },

    // {
    //     color: 'CDBAC7'
    // },

    // {
    //     color: '946F43'
    // },

    // {
    //     color: '66533C'
    // },

    // {
    //     color: '173A2F'
    // },

    // {
    //     color: '153944'
    // },

    // {
    //     color: '27548D'
    // },

    // {
    //     color: '438AAC'
    // }
];