import React from "react";

import { Routes, Route } from "react-router-dom";

import "@leoncvlt/ar-button";
import "@leoncvlt/ar-button/styles.css";

import Header from './component/header';

import Dashboard from './component/dashboard';
// import Video from './component/video';
import Games from "./component/games";
import Web3 from "./component/web3";
import WebAR from "./component/webAR";
import ViewAR from './component/viewAR';
import ViewARImg from './component/viewArImg';
import Projects from "./component/projects";
import Profile from "./component/profile"; 
import EditorPage from "./component/editorpage";
import PageNotFound from "./component/pageNotFound";
import Main from './component/homecomponents';
import Configurator from "./component/Configurator";

// import Apps from "./webARImg/components/App";

import { UserState } from "./dataContext";

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

export default function App() {
  const { user, mode } = UserState();

  // const PrivateRoute = ({ element: Component, ...rest }) => {
  // // Check if user is authenticated here
  //   const isAuthenticated = ''// ...

  //   return (
  //     <Route
  //       {...rest}
  //       element={(props) =>
  //         isAuthenticated ? (
  //           <Component {...props} />
  //         ) : (
  //           <Navigate to="/login" replace />
  //         )
  //       }
  //     />
  //   );
  // };

  return (
    <div className={mode===true ? 'dark' : 'light'}>
      <div style={{height: '10vh'}}>
        <Header />
      </div>

      <div style={{height: '90vh'}}>
        {(user && user.sign_in==='admin') ? <Routes>
          <Route index path="/" element={<Main />} />
          <Route path='/Games' element={<Games />} />
          <Route path='/WebXR' element={<WebAR />} />
          <Route path='/Web3' element={<Web3 />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/My-Projects' element={<Projects />} />
          <Route path='/ViewAR/:name' element={<ViewAR />} />
          <Route path='/ViewARImg/:name' element={<ViewARImg />} />
          <Route path='/Editor' element={<EditorPage />} />
          <Route path='/Profile' element={<Profile />} />
          <Route path='/Configurators' element={<Configurator />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes> : (user && user.sign_in==='google') ? <Routes>
          <Route index path="/" element={<Main />} />
          <Route path='/Games' element={<Games />} />
          <Route path='/WebXR' element={<WebAR />} />
          <Route path='/ViewAR/:name' element={<ViewAR />} />
          <Route path='/ViewARImg/:name' element={<ViewARImg />} />
          <Route path='/Profile' element={<Profile />} />
          <Route path='/Configurators' element={<Configurator />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes> : <Routes>
          <Route index path="/" element={<Main />} />
          <Route path='/Configurators' element={<Configurator />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>}
      </div>
    </div>
  );
};