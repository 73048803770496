import { useRef } from 'react';

import { Canvas, useFrame } from '@react-three/fiber';
import { 
    useGLTF, useTexture, 
    Decal, Environment,
} from '@react-three/drei';

import { easing } from 'maath';

import { useSnapshot } from 'valtio';

import { state } from './store';
import './index.css';

// import ReactLogo from '../../assets/logos/react.png';
// import GoogleLogo from '../../assets/logos/g-logo.png';
// import FacebookLogo from '../../assets/logos/f-logo.png';
// import ThreeLogo from '../../assets/logos/three js.png';
// import FirebaseLogo from '../../assets/logos/firebase.png';
// import PolygonLogo from '../../assets/logos/polygon-logo.png';
// import EtheriumLogo from '../../assets/logos/etherium-logo.png';

export const App = ({ position = [0, 0, 2.5], fov = 25 }) => (
    <Canvas shadows camera={{ position, fov }} gl={{ preserveDrawingBuffer: true }}>
        <ambientLight intensity={0.5} />

        <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/potsdamer_platz_1k.hdr" />
        
        <CameraRig>
            <group position={[0, 0.05, 0]} scale={[1.3, 1.15, 1.3]}>
                <Shirt />
            </group>    
        </CameraRig>
    </Canvas>
);

// function Backdrop() {
//     const shadows = useRef();
    
//     useFrame((state, delta) => easing.dampC(shadows.current.getMesh().material.color, state.color, 0.25, delta));
    
//     return (
//         <AccumulativeShadows ref={shadows} temporal frames={60} alphaTest={0.85} scale={10} rotation={[Math.PI / 2, 0, 0]} position={[0, 0, -0.14]}>
//             <RandomizedLight amount={4} radius={9} intensity={0.55} ambient={0.25} position={[5, 5, -10]} />
//             <RandomizedLight amount={4} radius={5} intensity={0.25} ambient={0.55} position={[-5, 5, -9]} />
//         </AccumulativeShadows>
//     );
// };

function CameraRig({ children }) {
    const group = useRef();

    const snap = useSnapshot(state);

    useFrame((state, delta) => {
        easing.damp3(state.camera.position, [!snap.intro ? state.viewport.width / 4 : 0, 0, 2], 0.25, delta)
        easing.dampE(group.current.rotation, [state.pointer.y / 10, state.pointer.x / 5, 0], 0.25, delta)
    });

    return <group ref={group}>{children}</group>
};

function Shirt(props) {
    const snap = useSnapshot(state);

    const texture = useTexture(`/${snap.decal}.png`);

    const { nodes, materials } = useGLTF('/shirt.glb');

    useFrame((state, delta) => easing.dampC(materials.lambert1.color, snap.color, 0.25, delta));

    return (
        <mesh castShadow geometry={nodes.T_Shirt_male.geometry} material={materials.lambert1} material-roughness={1} {...props} dispose={null}>
            <Decal position={[0, 0.04, 0.15]} rotation={[0, 0, 0]} scale={[0.15, 0.15, 0.25]} map={texture} />
        </mesh>
    );
};

useGLTF.preload('/shirt.glb');
['/react.png', '/google.png', '/fb.png', '/firebase.png', '/polygon.png', '/three.png', '/etherium.png'].forEach(useTexture.preload);