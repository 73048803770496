import React, { useRef, useState, useEffect } from "react";

import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF, ContactShadows, Environment, OrbitControls } from "@react-three/drei";

import { HexColorPicker } from "react-colorful";

import { proxy, useSnapshot } from "valtio";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

import ShoeModel from '../../shoe.glb';

const state = proxy({
    current: null,
    items: { 
        laces: "#fff", mesh: "#fff", 
        caps: "#fff", inner: "#fff", 
        band: "#fff", patch: "#fff",
        sole: "#fff", stripes: "#fff", 
    },
});

function Shoe() {
    const ref = useRef();
    const snap = useSnapshot(state);
    const { nodes, materials } = useGLTF(ShoeModel);
    const [hovered, set] = useState(null);

    useFrame((state) => {
        const t = state.clock.getElapsedTime()
        ref.current.rotation.set(Math.cos(t / 4) / 8, Math.sin(t / 4) / 8, -0.2 - (1 + Math.sin(t / 1.5)) / 20)
        ref.current.position.y = (1 + Math.sin(t / 1.5)) / 10
    });

    useEffect(() => {
        const cursor = `<svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path fill="rgba(255, 255, 255, 0.5)" d="M29.5 54C43.031 54 54 43.031 54 29.5S43.031 5 29.5 5 5 15.969 5 29.5 15.969 54 29.5 54z" stroke="#000"/><g filter="url(#filter0_d)"><path d="M29.5 47C39.165 47 47 39.165 47 29.5S39.165 12 29.5 12 12 19.835 12 29.5 19.835 47 29.5 47z" fill="${snap.items[hovered]}"/></g><path d="M2 2l11 2.947L4.947 13 2 2z" fill="#000"/><text fill="#000" style="#fff-space:pre" font-family="Inter var, sans-serif" font-size="10" letter-spacing="-.01em"><tspan x="35" y="63">${hovered}</tspan></text></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h64v64H0z"/></clipPath><filter id="filter0_d" x="6" y="8" width="47" height="47" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="2"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs></svg>`;
       
        const auto = `<svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="rgba(255, 255, 255, 0.5)" d="M29.5 54C43.031 54 54 43.031 54 29.5S43.031 5 29.5 5 5 15.969 5 29.5 15.969 54 29.5 54z" stroke="#000"/><path d="M2 2l11 2.947L4.947 13 2 2z" fill="#000"/></svg>`;
        
        if (hovered) {
            document.body.style.cursor = `url('data:image/svg+xml;base64,${btoa(cursor)}'), auto`
            
            return () => (
                document.body.style.cursor = `url('data:image/svg+xml;base64,${btoa(auto)}'), auto`
            )
        }
    }, [hovered]);

    return (
        <group
            ref={ref} scale={[1.5, 1.5, 1.5]}
            onPointerOver={(e) => (e.stopPropagation(), set(e.object.material.name))}
            onPointerOut={(e) => e.intersections.length === 0 && set(null)}
            onPointerMissed={() => (state.current = null)}
            onClick={(e) => (e.stopPropagation(), (state.current = e.object.material.name))}
        >
            <mesh receiveShadow castShadow geometry={nodes.shoe.geometry} material={materials.laces} material-color={snap.items.laces} />
            <mesh receiveShadow castShadow geometry={nodes.shoe_1.geometry} material={materials.mesh} material-color={snap.items.mesh} />
            <mesh receiveShadow castShadow geometry={nodes.shoe_2.geometry} material={materials.caps} material-color={snap.items.caps} />
            <mesh receiveShadow castShadow geometry={nodes.shoe_3.geometry} material={materials.inner} material-color={snap.items.inner} />
            <mesh receiveShadow castShadow geometry={nodes.shoe_4.geometry} material={materials.sole} material-color={snap.items.sole} />
            <mesh receiveShadow castShadow geometry={nodes.shoe_5.geometry} material={materials.stripes} material-color={snap.items.stripes} />
            <mesh receiveShadow castShadow geometry={nodes.shoe_6.geometry} material={materials.band} material-color={snap.items.band} />
            <mesh receiveShadow castShadow geometry={nodes.shoe_7.geometry} material={materials.patch} material-color={snap.items.patch} />
        </group>
    );
};

function Picker() {
    const snap = useSnapshot(state);

    // const handleDownload = () => {
    //     const link = document.createElement('a');

    //     link.setAttribute('download', 'canvas.png');

    //     link.setAttribute(
    //         'href', 
    //         document.querySelector('canvas').toDataURL('image/png').replace(
    //             'image/png', 
    //             'image/octet-stream'
    //         )
    //     );
        
    //     link.click();
    // };

    return (
        <div style={{ display: "block" }}>
            {snap.current ? 
                <HexColorPicker 
                    className="picker" color={snap.items[snap.current]} 
                    onChange={(color) => (state.items[snap.current] = color)} 
                /> : ''
            }
            
            {/* <button
                className="share"
                style={{ background: '#696969' }}
                onClick={() => handleDownload()}
            >
                <FontAwesomeIcon icon={faCameraRetro} /> Download
            </button> */}
        </div>
    );
};

const ShoeConfig = () => {
    return (
        <div>
            <div style={{width: 'auto', height: '55vh', textAlign: 'center'}}>
                <Canvas shadows camera={{ position: [0, 0, 4], fov: 45 }}>
                    <ambientLight intensity={0.7} />

                    <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />

                    <Shoe />

                    <Environment preset="city" />

                    <ContactShadows position={[0, -0.9, 0]} opacity={1} scale={20} blur={1.5} far={0.8} />

                    <OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} enableZoom={false} enablePan={false} />
                </Canvas>

                <Picker />
            </div>
        </div>
    );
};

export default ShoeConfig;