import React, { 
  Suspense, useRef, 
  useState, useMemo, useEffect 
} from 'react';

import { 
  Container, Row, Col, Card, CardFooter,
  CardBody, CardTitle, CardSubtitle, 
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';

import { Map, Overlay, Marker } from "pigeon-maps";

import { Chip } from 'primereact/chip';
import { Rating } from 'primereact/rating';
import { Dialog } from 'primereact/dialog';
import { Timeline } from 'primereact/timeline';
// import { Carousel } from 'primereact/carousel';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';

import * as THREE from 'three';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { 
  OrbitControls, ContactShadows, useGLTF, 
  CameraShake, MeshWobbleMaterial, Environment,
  MeshReflectorMaterial,
  // Sky, Stars
} from '@react-three/drei';

// import { easing } from 'maath';

// import {
//   Timeline, Events, 
//   TextEvent,
//   themes, createTheme,
// } from '@merc/react-timeline';

import { collection, addDoc } from "firebase/firestore"; 

import { db } from '../../firebase-config';

// import Scene from './subcomponent';
import HomeIcon from '../../assets/home.png';
import Profile from '../../assets/imgs/profile.png';
import ReactLogo from '../../assets/logos/react.png';
import ThreeLogo from '../../assets/logos/three js.png';
import FirebaseLogo from '../../assets/logos/firebase.png';

import IndiaFlag from '../../assets/flag.png';
import Visaka from '../../assets/logos/visaka.png';
import Plugxr from '../../assets/logos/plugxr.png';
import Marvij from '../../assets/logos/marvij.png';
import Linview from '../../assets/logos/linview.png';
import Atumlife from '../../assets/logos/atumlife.png';
import Loginsoft from '../../assets/logos/loginsoft.png';
import TNijam from '../../assets/logos/telangananijam.png';

// import ReactModel from '../../assets/model/react_logo.glb';
// import ThinkingPlace from '../../assets/portfolioMidScene.glb';

import './shaders/flag';
// import { WaveMaterial } from './shaders/waveMaterial';
// import { galaxyFragmentShader, galaxyVertexShader } from './shaders/galaxy';
import { UserState } from '../../dataContext';

import './index.css';
import Footer from '../footer';
// import Footer from '../footer';
// import VaporWave from './vaporwave';

export default function Main(){
  let skills = [
    { "id": 1, "name": "React Js", "description": "5+ years of experience", "image": ReactLogo, "rating": 4 },
    { "id": 2, "name": "React Native", "description": "2+ years of experience", "image": ReactLogo, "rating": 3 },
    { "id": 3, "name": "React Three Fiber", "description": "2 months of experience", "image": ThreeLogo, "rating": 2 },
    { "id": 4, "name": "Firebase", "description": "1 year of experience", "image": FirebaseLogo, "rating": 3},
  ];

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [desc, setDesc] = useState('');
  const [err, setErr] = useState(false);

  const [project, setProject] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.title = 'S.A.V.D - Home';
  }, []);

  // let selProj = [
  //   {name: 'atumlife'}, {name: 'plugxr'}, 
  //   {name: 'linview'}, {name: 'splitdeals'}, 
  //   {name: 'meebazaar'}, {name: 'gifteria'},
  // ];

  const { mode } = UserState();

  const position = [17.420271, 78.361218];

  // let events = [
  //   {status: 'marvij', icon: 'pi pi-verified', color: 'lime'},
  //   {status: 'loginsoft', icon: 'pi pi-verified', color: 'lime'},
  //   {status: 'plugxr', icon: 'pi pi-verified', color: 'lime'},
  //   {status: 'visaka', icon: 'pi pi-check-circle', color: '#ffb900'},
  // ];

  // const responsiveOptions = [
  //   {
  //     breakpoint: '1080px',
  //     numVisible: 3,
  //     numScroll: 3
  //   },
  //   {
  //     breakpoint: '720px',
  //     numVisible: 2,
  //     numScroll: 2
  //   },
  //   {
  //     breakpoint: '480px',
  //     numVisible: 1,
  //     numScroll: 1
  //   }
  // ];

  // const Model2 = ({...props}) => {
  //   const { scene } = useGLTF(ThinkingPlace);

  //   return <primitive object={scene} {...props} />
  // };

  const Model1 = ({...props}) => {
    const { scene } = useGLTF("https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/react-logo/model.gltf");

    return <group>
      <primitive object={scene} {...props} />
      <MeshReflectorMaterial color={"dodgerblue"} />
    </group>
  };

  const Flag = () => {
    const material = useRef();
    const [texture] = useLoader(THREE.TextureLoader, [IndiaFlag]);
  
    useFrame(({ clock }) => {
      if (material.current) {
        material.current.uniforms.uTime.value = (clock.getElapsedTime() * 2.5);
      }
    });

    return (
      <mesh position={[0, 0, 0]} scale={[0.8, 0.8, 1.25]}>
        <planeGeometry args={[1.3, 1, 64, 64]} />
        <customMaterial ref={material} attach="material" map={texture} side={THREE.DoubleSide} />
      </mesh>
    );
  };

  const Earth = () => {
    const ref = useRef();

    const { gl } = useThree();

    const texture = useLoader(
      THREE.TextureLoader,
      'https://cdn.jsdelivr.net/gh/Sean-Bradley/React-Three-Fiber-Boilerplate@displacementMap/public/img/worldColour.5400x2700.jpg'
    );

    const displacementMap = useLoader(
      THREE.TextureLoader,
      'https://cdn.jsdelivr.net/gh/Sean-Bradley/React-Three-Fiber-Boilerplate@displacementMap/public/img/gebco_bathy_2700x1350.jpg'
    );
  
    const material = 0.5;
  
    useEffect(() => {
      texture.anisotropy = gl.capabilities.getMaxAnisotropy();
    }, [texture, gl]);
  
    return (
      <mesh ref={ref} castShadow={true} receiveShadow={true} scale={2}>
        <icosahedronGeometry args={[1, 128]} />

        <meshStandardMaterial
          wireframe={false} map={texture}
          displacementMap={displacementMap}
          displacementScale={material}
        />
      </mesh>
    );
  };

  const EarthNetwork = () => {
    const groupRef = useRef();
    const particlesRef = useRef();
    const linesGeometryRef = useRef();

    const maxParticleCount = 1000
    const particleCount = 500
    const r = 10
    const rHalf = r / 2
    const maxConnections = 20;
    const minDistance = 2.5
    let vertexpos = 0
    let colorpos = 0
    let numConnected = 0

    const segments = maxParticleCount * maxParticleCount;
    const positions = useMemo(() => new Float32Array(segments * 3), [segments]);
    const colors = useMemo(() => new Float32Array(segments * 3), [segments]);

    const particlePositions = useMemo(() => new Float32Array(maxParticleCount * 3), []);

    const particlesData = useMemo(() => [], []);

    const v = useMemo(() => new THREE.Vector3(), []);

    useEffect(() => {
      for (let i = 0; i < maxParticleCount; i++) {
        const x = Math.random() * r - r / 2;
        const y = Math.random() * r - r / 2;
        const z = Math.random() * r - r / 2;

        particlePositions[i * 3] = x;
        particlePositions[i * 3 + 1] = y;
        particlePositions[i * 3 + 2] = z;

        const v = new THREE.Vector3(
          -1 + Math.random() * 2,
          -1 + Math.random() * 2,
          -1 + Math.random() * 2
        );
          
        particlesData.push({ 
          velocity: v.normalize().divideScalar(50), 
          numConnections: 0,
        });
      };

      particlesRef.current.setDrawRange(0, particleCount)
    });

    useFrame((_) => {
      vertexpos = 0;
      colorpos = 0;
      numConnected = 0;

      for (let i = 0; i < particleCount; i++) { particlesData[i].numConnections = 0 };

      for (let i = 0; i < particleCount; i++) {
        const particleData = particlesData[i];

        v.set(
          particlePositions[i * 3],
          particlePositions[i * 3 + 1],
          particlePositions[i * 3 + 2]
        ).add(particleData.velocity).setLength(10);

        particlePositions[i * 3] = v.x;
        particlePositions[i * 3 + 1] = v.y;
        particlePositions[i * 3 + 2] = v.z;

        if (particlePositions[i * 3 + 1] < -rHalf || particlePositions[i * 3 + 1] > rHalf)
          particleData.velocity.y = -particleData.velocity.y

        if (particlePositions[i * 3] < -rHalf || particlePositions[i * 3] > rHalf)
          particleData.velocity.x = -particleData.velocity.x

        if (particlePositions[i * 3 + 2] < -rHalf || particlePositions[i * 3 + 2] > rHalf)
          particleData.velocity.z = -particleData.velocity.z

        if (particleData.numConnections >= maxConnections) continue

        for (let j = i + 1; j < particleCount; j++) {
          const particleDataB = particlesData[j];

          if (particleDataB.numConnections >= maxConnections) continue

          const dx = particlePositions[i * 3] - particlePositions[j * 3]
          const dy = particlePositions[i * 3 + 1] - particlePositions[j * 3 + 1]
          const dz = particlePositions[i * 3 + 2] - particlePositions[j * 3 + 2]
          const dist = Math.sqrt(dx * dx + dy * dy + dz * dz)

          if (dist < minDistance) {
            particleData.numConnections++
            particleDataB.numConnections++

            const alpha = 1.0 - dist / minDistance

            positions[vertexpos++] = particlePositions[i * 3]
            positions[vertexpos++] = particlePositions[i * 3 + 1]
            positions[vertexpos++] = particlePositions[i * 3 + 2]

            positions[vertexpos++] = particlePositions[j * 3]
            positions[vertexpos++] = particlePositions[j * 3 + 1]
            positions[vertexpos++] = particlePositions[j * 3 + 2]

            colors[colorpos++] = alpha
            colors[colorpos++] = alpha
            colors[colorpos++] = alpha

            colors[colorpos++] = alpha
            colors[colorpos++] = alpha
            colors[colorpos++] = alpha

            numConnected++
          }
        }
      };

      linesGeometryRef.current.setDrawRange(0, numConnected * 2);
      linesGeometryRef.current.attributes.position.needsUpdate = true;
      linesGeometryRef.current.attributes.color.needsUpdate = true;
      particlesRef.current.attributes.position.needsUpdate = true;
    });

    return (
      <group ref={groupRef} dispose={null} scale={0.26}>
        <points>
          <bufferGeometry ref={particlesRef}>
            <bufferAttribute
              attach="attributes-position"
              count={particleCount}
              array={particlePositions}
              itemSize={3}
            />
          </bufferGeometry>

          <pointsMaterial
            color={'white'}
            size={3}
            blending={THREE.AdditiveBlending}
            transparent={true}
            sizeAttenuation={false}
          />
        </points>
        
        <lineSegments>
          <bufferGeometry ref={linesGeometryRef}>
            <bufferAttribute
              attach="attributes-position"
              count={positions.length / 3}
              array={positions}
              itemSize={3}
            />
            <bufferAttribute
              attach="attributes-color"
              count={colors.length / 3}
              array={colors}
              itemSize={3}
            />
          </bufferGeometry>
          <lineBasicMaterial
            vertexColors={true}
            blending={THREE.AdditiveBlending}
            transparent={true}
          />
        </lineSegments>
      </group>
    );
  };

  // const MarvijCard = () => (
  //   <div className='template-card'>
  //     <div style={{display: 'flex', flexDirection: 'column'}}>
  //       <div style={{textAlign: 'center'}}>
  //         <img src={Marvij} style={{width: '200px', height: 'auto'}} />
  //       </div>
        
  //       <h4 style={{textAlign: 'center', textDecorationLine: 'underline'}}>
  //         <a href='https://marvij.com/' target='_blank' style={mode===false ? {color: 'black'} : {color: 'white'}}>Marvij</a>
  //         <h6 style={{marginTop: '5px'}}>2018(Jun) - 2020(Mar)</h6>
  //       </h4>
        
  //       <div style={{textAlign: 'center'}}>
  //         Worked on Split Deal's, MEE BAZAAR & Gifteria projects.
  //       </div>
  //     </div>
  //   </div>
  // );

  // const PlugXRCard = () => (
  //   <div className='template-card'>
  //     <div style={{display: 'flex', flexDirection: 'column'}}>
  //       <div style={{textAlign: 'center'}}>
  //         <img src={Plugxr} style={{width: '200px', height: 'auto'}} />
  //       </div>

  //       <h4 style={{textAlign: 'center', textDecorationLine: 'underline'}}>
  //         <a href='https://www.plugxr.com/' target='_blank' style={mode===false ? {color: 'black'} : {color: 'white'}}>Plug XR</a>
  //         <h6 style={{marginTop: '5px'}}>2021(Jan) - 2022(Jun)</h6>
  //       </h4>

  //       <div style={{textAlign: 'center'}}>
  //         Worked on PlugXR Project.
  //       </div>
  //     </div>
  //   </div>
  // );

  // const VisakaCard = () => (
  //   <div className='template-card'>
  //     <div style={{display: 'flex', flexDirection: 'column'}}>
  //       <div style={{textAlign: 'center'}}>
  //         <img src={Visaka} style={{width: '200px', height: 'auto'}} />
  //       </div>

  //       <h4 style={{textAlign: 'center', marginTop: '-6vh', textDecorationLine: 'underline'}}>
  //         <a href='https://www.visaka.co/' target='_blank' style={mode===false ? {color: 'black'} : {color: 'white'}}>Visaka Industries</a>
  //         <h6 style={{marginTop: '5px'}}>2022(Jun) - Present</h6>
  //       </h4>

  //       <div style={{textAlign: 'center'}}>
  //         Worked on ATUM Life Project.
  //       </div>
  //     </div>
  //   </div>
  // );

  // const LoginSoftCard = () => (
  //   <div className='template-card'>
  //     <div style={{display: 'flex', flexDirection: 'column'}}> 
  //       <div style={{textAlign: 'center'}}>
  //         <img src={Loginsoft} style={{width: '200px', height: 'auto'}} />
  //       </div>

  //       <h4 style={{textAlign: 'center', textDecorationLine: 'underline'}}>
  //         <a href='https://www.loginsoft.com/' target='_blank' style={mode===false ? {color: 'black'} : {color: 'white'}}>Loginsoft</a>
  //         <h6 style={{marginTop: '5px'}}>2020(Mar) - 2020(Sept)</h6>
  //       </h4>

  //       <div style={{textAlign: 'center'}}>
  //         Worked on Linview Project.
  //       </div>
  //     </div>
  //   </div>
  // );

  // const customizedMarker = (item) => {
  //   return (
  //     <span style={{ 
  //       color: item.color, 
  //       width: '32px',
  //       height: '32px',
  //       borderRadius: '100%',
  //       display: 'flex',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //       backgroundColor: '#2f4f4f'
  //     }}>
  //       <i className={item.icon}></i>
  //     </span>
  //   );
  // };

  // const customizedContent = (item) => {
  //   return <div>
  //     {item.status==='marvij' && MarvijCard()}

  //     {item.status==='loginsoft' && LoginSoftCard()}

  //     {item.status==='plugxr' && PlugXRCard()}

  //     {item.status==='visaka' && VisakaCard()}
  //   </div>;
  // };

  // const BasicParticles = () => {
  //   const points = useRef();

  //   return (
  //     <points ref={points}>
  //       <sphereGeometry args={[1, 48, 48]} />
  //       <pointsMaterial color="#FF4500" size={0.25} sizeAttenuation />
  //     </points>
  //   );
  // };

  const handleRaise = async () => {
    if(name!=='' && email!=='' && desc!=='' && validEmail(email)){
      try {
        const docRef = await addDoc(collection(db, "requests"), {
          'Name': name,
          'Email': email,
          'Info': desc,
          'checked': false,
        });
      
        console.log("Id ", docRef.id); setErr(false);
        setDesc(''); setEmail(''); setName('');
      } catch (e) {
        console.log("err ", e);
      }
    }else{
      setErr(true);
    }
  };

  const validEmail = (val) => {
    var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    return !filter.test(val) ? false : true;
  };

  const MosquitoParticles = (props) => {
    const { count } = props;
  
    const points = useRef();
  
    const particlesPosition = useMemo(() => {
      const positions = new Float32Array(count * 3);

      const distance = 1;
      
      for (let i = 0; i < count; i++) {
        const theta = THREE.MathUtils.randFloatSpread(360); 
        const phi = THREE.MathUtils.randFloatSpread(360); 
  
        let x = distance * Math.sin(theta) * Math.cos(phi)
        let y = distance * Math.sin(theta) * Math.sin(phi);
        let z = distance * Math.cos(theta);
  
        positions.set([x, y, z], i * 3);
      }
      
      return positions;
    }, [count]);
  
    useFrame((state) => {
      const { clock } = state;
      
      for (let i = 0; i < count; i++) {
        const i3 = i * 3;
  
        points.current.geometry.attributes.position.array[i3] += Math.sin(clock.elapsedTime + Math.random() * 10) * 0.01;
        points.current.geometry.attributes.position.array[i3 + 1] += Math.cos(clock.elapsedTime + Math.random() * 10) * 0.01;
        points.current.geometry.attributes.position.array[i3 + 2] += Math.sin(clock.elapsedTime + Math.random() * 10) * 0.01;
      }
  
      points.current.geometry.attributes.position.needsUpdate = true;
    });
  
    return (
      <points ref={points}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={particlesPosition.length / 3}
            array={particlesPosition}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial size={0.012} color="#5786F5" sizeAttenuation depthWrite={false} />
      </points>
    );
  };

  const HubParticles = (props) => {
    const { count, shape } = props;
  
    const points = useRef();
  
    const particlesPosition = useMemo(() => {
      const positions = new Float32Array(count * 3);
  
      // if (shape === "box") {
      //   for (let i = 0; i < count; i++) {
      //     let x = (Math.random() - 0.5) * 2;
      //     let y = (Math.random() - 0.5) * 2;
      //     let z = (Math.random() - 0.5) * 2;
  
      //     positions.set([x, y, z], i * 3);
      //   }
      // }
  
      // if (shape === "sphere") {
        const distance = 1;
       
        for (let i = 0; i < count; i++) {
          const theta = THREE.MathUtils.randFloatSpread(360); 
          const phi = THREE.MathUtils.randFloatSpread(360); 
  
          let x = distance * Math.sin(theta) * Math.cos(phi)
          let y = distance * Math.sin(theta) * Math.sin(phi);
          let z = distance * Math.cos(theta);
  
          positions.set([x, y, z], i * 3);
        }
      // }
  
      return positions;
    }, [count, shape]);
  
    return (
      <points ref={points}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={particlesPosition.length / 3}
            array={particlesPosition}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial size={0.015} color="#5786F5" sizeAttenuation depthWrite={false} />
      </points>
    );
  };

  // const GalaxyParticles = (props) => {
  //   const { count } = props;

  //   const radius = 2;

  //   const points = useRef();
  
  //   const particlesPosition = useMemo(() => {
  //     const positions = new Float32Array(count * 3);
      
  //     for (let i = 0; i < count; i++) {
  //       const distance = Math.sqrt(Math.random()) * radius;
  //       const theta = THREE.MathUtils.randFloatSpread(360); 
  //       const phi = THREE.MathUtils.randFloatSpread(360); 
  
  //       let x = distance * Math.sin(theta) * Math.cos(phi)
  //       let y = distance * Math.sin(theta) * Math.sin(phi);
  //       let z = distance * Math.cos(theta);
  
  //       positions.set([x, y, z], i * 3);
  //     };
      
  //     return positions;
  //   }, [count]);
  
  //   const uniforms = useMemo(() => ({
  //     uTime: {
  //       value: 0.0
  //     },
  //     uRadius: {
  //       value: radius
  //     }
  //   }), [])
  
  //   useFrame((state) => {
  //     const { clock } = state;
  
  //     points.current.material.uniforms.uTime.value = clock.elapsedTime;
  //   });
  
  //   return (
  //     <points ref={points}>
  //       <bufferGeometry>
  //         <bufferAttribute
  //           attach="attributes-position"
  //           count={particlesPosition.length / 3}
  //           array={particlesPosition}
  //           itemSize={3}
  //         />
  //       </bufferGeometry>
  //       <shaderMaterial
  //         blending={THREE.AdditiveBlending}
  //         depthWrite={false}
  //         fragmentShader={galaxyFragmentShader}
  //         vertexShader={galaxyVertexShader}
  //         uniforms={uniforms}
  //       />
  //     </points>
  //   );
  // };

  // const [mount, setMount] = useState(false);

  // useEffect(() => {
  //   setMount(true);
  // }, []);

  // const ShaderPlane = () => {
  //   const ref = useRef();
  //   const { viewport, size } = useThree();

  //   useFrame((state, delta) => {
  //     ref.current.time += delta;
  //     easing.damp3(ref.current.pointer, state.pointer, 0.2, delta);
  //   });

  //   return (
  //     <mesh scale={[viewport.width, viewport.height, 1]}>
  //       <planeGeometry />
  //       <waveMaterial ref={ref} key={WaveMaterial.key} resolution={[size.width * viewport.dpr, size.height * viewport.dpr]} />
  //     </mesh>
  //   );
  // };

  return (
    <ScrollPanel style={{height: '100%'}}>
      <Container>
        <Row style={{justifyContent: 'center'}}>
          <Col xs={12} style={{padding: '1%'}}>
            {/* Title */}
            <div>
              <Row>
                <Col xs={12} sm={6}>
                  <h1 style={mode===true ? {fontStyle: 'italic'} : {fontStyle: 'italic', color: '#2F4F4F'}}>Sai Akhil</h1>
                  <h1 style={mode===true ? {fontStyle: 'italic'} : {fontStyle: 'italic', color: '#2F4F4F'}}>Varma Datla</h1>
                  <p style={mode===true ? {fontStyle: 'italic', fontWeight: 'bold'} : {fontStyle: 'italic', color: '#2F4F4F', fontWeight: 'bold'}}> - React Developer</p>
                  <hr style={{width: '250px', border: '2px solid #191970', borderRadius: '5px'}} />
                </Col>

                <Col xs={12} sm={6} style={{textAlign: 'center'}}>
                  <img src={Profile} style={{border: '3px solid rgb(95, 158, 160)', borderRadius: '50%', height: '30vh'}} />
                  
                  {/* <div className='verify'>
                    <div className='point-burst'>
                      <div className='thick-check-mark'></div>
                    </div>
                  </div> */}
                </Col>
              </Row>
            </div>

            {/* Text */}
            <div>
              <p style={{fontStyle: 'oblique', fontSize: '16px'}}>
                Experienced React developer - web development, specializing in building interactive user interfaces, component-based architecture, state management and optimizing performance. Can write clean code, and troubleshoot complex UI issues, ensuring responsive and user-friendly web applications.
              </p>

              <p style={{fontStyle: 'oblique', fontSize: '16px'}}>
                - Two steps away from becoming Full-Stack Developer and Learning Three Js (React Three Fiber).
              </p>
            </div>

            {/* Three JS Examples */}
            <div>
              <Row>
                <Col xs={12}>
                  <h3 style={{textAlign: 'center', fontStyle: 'oblique'}}>Three Js Examples</h3>
                </Col>

                <Col xs={12} sm={6} md={4}>
                  <div style={{boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)', borderRadius: '5px', backgroundColor: 'whitesmoke'}}>
                    <Canvas shadows='variance'>
                      <ambientLight intensity={0.3} color="steelblue" />
                      <directionalLight color="red" intensity={5} />
                      <mesh scale={[5, 5, 5]} castShadow receiveShadow>
                        <sphereGeometry />
                        <MeshWobbleMaterial wireframe={true}  />
                      </mesh>
                      <OrbitControls autoRotate enableZoom={false} enableDamping={false} />
                    </Canvas>
                  </div>
                </Col>
                
                <Col xs={12} sm={6} md={4} >
                  <div style={{boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)', borderRadius: '5px', backgroundColor: 'whitesmoke'}}>
                    <Canvas shadows='variance'>
                      <directionalLight castShadow color="dodgerblue" intensity={5} />
                      <ambientLight color="dodgerblue" intensity={0.5} />
                      <Suspense fallback={null}>
                        <group position={[0, -2.5, 0]}>
                          <Model1 scale={[2, 2, 2]} position={[0, 0.5, 0]} castShadow receiveShadow />
                          <CameraShake maxPitch={0.05} maxRoll={0.05} maxYaw={0.05} pitchFrequency={0.5} rollFrequency={0.5} yawFrequency={0.5} />
                          <ContactShadows scale={20} blur={3} far={1000} />
                        </group>
                      </Suspense>
                      <OrbitControls enableDamping={false} enableZoom={false} enableRotate={false} enablePan={false} />
                    </Canvas>
                  </div>
                </Col>

                <Col xs={12} sm={6} md={4}>
                  <div style={{boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)', borderRadius: '5px', backgroundColor: 'whitesmoke'}}>
                    <Canvas camera={{ position: [0, 0, 1] }}>
                      <ambientLight />
                      <Suspense fallback={null}>
                        <Flag />
                      </Suspense>
                      <OrbitControls 
                        enableRotate={false} enableDamping={false} 
                        enablePan={false} enableZoom={false} 
                      />
                    </Canvas>
                  </div>
                </Col>

                <Col xs={12} sm={6} md={4}>
                  <div style={{boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)', borderRadius: '5px', backgroundColor: 'whitesmoke'}}>
                    <Canvas camera={{ position: [1.5, 1.5, 1.5] }}>
                      <ambientLight intensity={0.5} />
                      <MosquitoParticles count={5000} />
                      <HubParticles count={500} />
                      <OrbitControls 
                        autoRotate autoRotateSpeed={5.5} 
                        enablePan={false} enableRotate={false} 
                        enableDamping={false} 
                      />
                    </Canvas>
                  </div>
                </Col>  

                <Col xs={12} sm={6} md={4}>
                  <div style={{boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)', borderRadius: '5px', backgroundColor: 'whitesmoke'}}>
                    <Canvas shadows='variance'>
                      <Environment files="https://cdn.jsdelivr.net/gh/Sean-Bradley/React-Three-Fiber-Boilerplate@displacementMap/public/img/venice_sunset_1k.hdr" />
                      <directionalLight
                        intensity={Math.PI}
                        position={[4, 0, 2]}
                        castShadow={true}
                        shadow-mapSize-width={2048}
                        shadow-mapSize-height={2048}
                        shadow-camera-left={-2}
                        shadow-camera-right={2}
                        shadow-camera-top={-2}
                        shadow-camera-bottom={2}
                        shadow-camera-near={0.1}
                        shadow-camera-far={7}
                      />
                      <ambientLight intensity={0.5} />
                      <EarthNetwork />
                      <Earth />
                      {/* <GalaxyParticles count={5000} /> */}
                      <OrbitControls 
                        enablePan={false} enableDamping={false} 
                        enableRotate={true} enableZoom={false} 
                      />
                    </Canvas>
                  </div>
                </Col>     

                {/* <Col xs={12} sm={6} md={4}>
                  <Canvas style={{borderRadius: '5px'}}>
                    <ShaderPlane />
                  </Canvas>
                </Col> */}

                <Col xs={12} sm={6} md={4}>
                  <div style={{
                    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)', 
                    borderRadius: '5px', display: 'flex', height: '100%',
                    justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                    backgroundColor: 'whitesmoke', fontSize: '18px', fontWeight: '700'
                  }}>
                    <a style={{color: '#708090', textDecoration: 'none'}} onClick={() => navigate('/Configurators')}> Configurator's </a>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={12} style={{padding: '1%', textAlign: 'center', fontWeight: '600', marginTop: '5px'}}>
            <Row>
              <Col xs={12}>
                <h4 style={{fontStyle: 'oblique', display: 'flex', justifyContent: 'center'}}>
                  <hr style={{ width: '10vw' }} />
                  <div style={{marginLeft: '1vw', marginRight: '1vw'}}>Skills</div>
                  <hr style={{ width: '10vw' }} />
                </h4>
              </Col>

              <Col xs={12}>
                <Row>
                {skills && skills.map((item) => 
                  <Col xs={12} sm={6} md={3}>
                    <Card className='skils'>
                      <div style={item.name === 'React Three Fiber' ? {textAlign: 'center', padding: '5px'} : {textAlign: 'center'}}>
                        <img
                          src={item.image}  
                          style={item.name === 'React Three Fiber' ? {width: '200px', height: '190px'} : { width: '220px', height: '200px' }}
                        />
                      </div>
                      <CardBody>
                        <CardTitle 
                          tag="h5" 
                          style={{textAlign: 'left', marginLeft: '15px', color: '#1a1110', fontWeight: '700'}}
                        >
                          {item.name}
                        </CardTitle>
                        <CardSubtitle
                          className="mb-2 text-muted"
                          tag="h6"
                          style={{textAlign: 'left', marginLeft: '15px'}}
                        >
                          {item.description}
                          
                          <Rating value={item.rating} cancel={false} style={{paddingTop: '7.5px'}} />  
                        </CardSubtitle>
                      </CardBody>
                    </Card> 
                  </Col>
                )}
                </Row>
              </Col>
            </Row>
          </Col>
         
          {/* <Col xs={12} style={{padding: '1%', textAlign: 'center', fontWeight: '600', marginTop: '5px'}}>
            <h4 style={{fontStyle: 'oblique', display: 'flex', justifyContent: 'center'}}>
              <hr style={{ width: '10vw' }} />
              <div style={{marginLeft: '1vw', marginRight: '1vw'}}>Work Experience</div>
              <hr style={{ width: '10vw' }} />
            </h4>
              
            <div>
              <Timeline 
                value={events} align="alternate" 
                className="customized-timeline" 
                marker={customizedMarker} 
                content={customizedContent} 
              />
            </div>
          </Col> */}
          
          <Col xs={12} style={{padding: '1%', marginTop: '1px'}}>
            <h4 style={{fontStyle: 'oblique', display: 'flex', justifyContent: 'center',  textAlign: 'center'}}>
              <hr style={{ width: '10vw' }} />
              <div style={{marginLeft: '1vw', marginRight: '1vw'}}>Project's</div>
              <hr style={{ width: '10vw' }} />
            </h4>

            <div style={{marginTop: '5px'}}>
              <Row>
                <Col xs={12} sm={4}>
                  <Card className='card-width'>
                    <img src={TNijam} style={{padding: '8px', height: '100px', backgroundColor: 'navy', border: '1px solid navy', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}} />

                    <CardBody style={{borderTop: '1px dotted #0f0f0f'}}>
                      <CardSubtitle className='info'>
                        Welcome to Telangana Nijam News - Telugu Daily Newspaper, which provide Today's News Headlines in Telugu. Read Epaper of Nijam News for updates ...
                      </CardSubtitle>

                      <hr style={{margin: '3px'}} />

                      <div style={{textAlign: 'center'}}>
                        <Chip label='React Js' className='react_chip' />
                      </div>

                      <div className='viewtop'>
                        <div className='button-865' onClick={() => {setOpen(true); setProject('telangananijam')}}>View More</div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} sm={4}>
                  <Card className='card-width'>
                    <img src={Atumlife} style={{padding: '1px', height: '100px'}} />

                    <CardBody style={{borderTop: '1px dotted #0f0f0f'}}>
                      <CardSubtitle className='info'>
                        ATUM Life is the first of its kind Net Zero Sustainability Experience Centre located in metropolitan cities. We offer a diverse range of ...
                      </CardSubtitle>

                      <hr style={{margin: '3px'}} />

                      <div style={{textAlign: 'center'}}>
                        <Chip label='React Js' className='react_chip' />

                        <Chip label='React Native' className='react_chip' />

                        <Chip label='Firebase' className='firebase_chip' />

                        <Chip label='PayTM' className='paytm_chip' />
                      </div>

                      <div className='viewtop'>
                        <div className='button-865' onClick={() => {setOpen(true); setProject('atumlife')}}>View More</div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} sm={4}>
                  <Card className='card-width'>
                    <img src={Plugxr} style={{padding: '15px', height: '100px'}} />

                    <CardBody style={{borderTop: '1px dotted #0f0f0f'}}>
                      <CardSubtitle className='info'>
                        Plug XR is an Augmented Reality (AR), Virtual reality (VR), Mixed Reality (MR) / Extended Reality (XR) with an aim to accelerate ...
                      </CardSubtitle>

                      <hr style={{margin: '3px'}} />

                      <div style={{textAlign: 'center'}}>
                        <Chip label='React Js' className='react_chip' />

                        <Chip label='Firebase' className='firebase_chip' />

                        <Chip label='Three Js' className='three_chip' />
                      </div>
                    
                      <div className='viewtop'>
                        <div className='button-865' onClick={() => {setOpen(true); setProject('plugxr')}}>View More</div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} sm={4}>
                  <Card className='card-width'>
                    <img src={Linview} style={{padding: '30px', height: '100px', backgroundColor: '#222f3f', border: '1px solid 222f3f', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}} />

                    <CardBody style={{borderTop: '1px dotted #0f0f0f'}}>
                      <CardSubtitle className='info'>
                        Linview provides the user to store the data, it shows the cloud management. It can alert the user or administrator about data limit, new users ...
                      </CardSubtitle>

                      <hr style={{margin: '3px'}} />

                      <div style={{textAlign: 'center'}}>
                        <Chip label='React Js' className='react_chip' />

                        <Chip label='Kibana' className='kibana_chip' />
                      </div>
                        
                      <div className='viewtop'>
                        <div className='button-865' onClick={() => {setOpen(true); setProject('linview')}}>View More</div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                
                <Col xs={12} sm={4}>
                  <Card className='card-width'>
                    <div style={{padding: '28px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <div style={{color: '#000', fontSize: '29px', fontWeight: '700'}}>Split</div>
                      <div style={{color: 'rgb(255, 0, 98)', fontSize: '29px', fontWeight: '700'}}>&nbsp;Deals</div>
                    </div>

                    <CardBody style={{borderTop: '1px dotted #0f0f0f'}}>
                      <CardSubtitle className='info'>
                        Split Deals brings you the best shopping deals around you and across the city. 
                        Split deals had a wide range of categories. You could probably get ...
                      </CardSubtitle>

                      <hr style={{margin: '3px'}} />

                      <div style={{textAlign: 'center'}}>
                        <Chip label='React Js' className='react_chip' />
                      </div>

                      <div className='viewtop'>
                        <div className='button-865' onClick={() => {setOpen(true); setProject('splitdeals')}}>View More</div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} sm={4}>
                  <Card className='card-width'>
                    <div style={{padding: '28px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <div style={{color: 'navy', fontSize: '29px', fontWeight: '700'}}>Mee</div>
                      <div style={{color: 'orangered', fontSize: '29px', fontWeight: '700'}}>&nbsp;Bazaar</div>
                    </div>
                    
                    <CardBody style={{borderTop: '1px dotted #0f0f0f'}}>
                      <CardSubtitle className='info'>
                        MEE BAZAAR is an online grocery store which is one stop solution for all the GROCERY, Beauty products & Regular home needs. 
                        Our Expertise lies in ...
                      </CardSubtitle>

                      <hr style={{margin: '3px'}} />

                      <div style={{textAlign: 'center'}}>
                        <Chip label='React Js' className='react_chip' />

                        <Chip label='React Native' className='react_chip' />
                      </div>

                      <div className='viewtop'>
                        <div className='button-865' onClick={() => {setOpen(true); setProject('meebazaar')}}>View More</div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs={12} sm={4}>
                  <Card className='card-width'>
                    <div style={{padding: '28px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <div style={{color: '#0569D1', fontSize: '29px', fontWeight: '800'}}>GIFTERIA</div>
                    </div>
                    
                    <CardBody style={{borderTop: '1px dotted #0f0f0f'}}>
                      <CardSubtitle className='info'>
                        Online Gift Store-Personalized Gifts, Frames, Micro Art Pencils, Photo Cakes, Birthday Gifts, Mother Gifts, Father Gifts etc. 
                        This is an ecommerce mobile app ...
                      </CardSubtitle>

                      <hr style={{margin: '3px'}} />

                      <div style={{textAlign: 'center'}}>
                        <Chip label='React Js' className='react_chip' />

                        <Chip label='React Native' className='react_chip' />
                      </div>

                      <div className='viewtop'>
                        <div className='button-865' onClick={() => {setOpen(true); setProject('gifteria')}}>View More</div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          
          <Col xs={12} style={{padding: '1%', marginTop: '5px'}}>
            <div>
              <div style={{textAlign: 'center'}}>
                <h4 style={{fontStyle: 'oblique', display: 'flex', justifyContent: 'center',  textAlign: 'center'}}>
                  <hr style={{ width: '10vw' }} />
                  <div style={{marginLeft: '1vw', marginRight: '1vw'}}>Contact Me</div>
                  <hr style={{ width: '10vw' }} />
                </h4>
              </div>

              <Row>
                <Col xs={12} sm={5} style={{textAlign: 'center'}}>
                  <Row style={{padding: '5px'}}>
                    <Col xs={6}>
                      <span className="p-float-label">
                        <InputText 
                          id="name" value={name} className='text'
                          onChange={(e) => setName(e.target.value)} 
                        />
                        <label htmlFor="name" className={(err===true && email!=='') ? 'p-invalid' : ''}>Name <span style={{color: 'red'}}>*</span></label>
                      </span>
                      {(err===true && name==='') && <small style={{color: 'maroon'}}>Enter your Name</small>}
                    </Col>

                    <Col xs={6}>
                      <span className="p-float-label">
                        <InputText 
                          id="email" value={email} className='text'
                          onChange={(e) => setEmail(e.target.value)} 
                        />
                        <label htmlFor="email" className={(err===true && email!=='') ? 'p-invalid' : ''}>Email <span style={{color: 'red'}}>*</span></label>
                      </span>
                      {(err===true && email==='') && <small style={{color: 'maroon'}}>Enter your Email</small>}
                      {(err===true && email!=='' && validEmail(email)) && <small style={{color: 'maroon'}}>Please Enter Vaild Email</small>}
                    </Col>

                    <Col xs={12} style={{marginTop: '10px'}}>
                      <span className="p-float-label">
                        <InputTextarea 
                          id="desc" value={desc} rows={5}
                          className={'textarea'} cols={20}
                          onChange={(e) => setDesc(e.target.value)} 
                        />
                        <label htmlFor="desc" className={(err===true && email!=='') ? 'p-invalid' : ''}>Let's us discuss <span style={{color: 'red'}}>*</span></label>
                      </span>
                      {(err===true && desc==='') && <small style={{color: 'maroon'}}>Info (or) Discussion</small>}
                    </Col>

                    <Col xs={12} style={{marginTop: '5px', display: 'flex', justifyContent: 'center'}}>
                      <div style={{backgroundColor: '#f5f5f5', borderRadius: '8px'}}>
                        <div className='button-86' onClick={() => handleRaise()}>Submit</div>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col xs={0} sm={1}></Col>

                <Col xs={12} sm={6} style={{textAlign: 'center'}}>
                  <Card style={{ width: '99%', borderRadius: '5px', padding: '5px', margin: '2px' }}>
                    <Map
                      height={250} dprs={[2, 4]}
                      center={position} zoom={15}
                    >
                      <Marker 
                        width={45} anchor={position} 
                        color={'firebrick'}
                      />

                      <Overlay anchor={position} offset={[0, 36]}>
                        <img src={HomeIcon} width={90} height={90} alt='home' />
                      </Overlay>
                    </Map>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>

          <Footer />
        </Row>
      </Container>

      <Dialog visible={open} closable={false}>
        {project==='telangananijam' && <div>
          <Card>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={TNijam} style={{padding: '8px', height: '100px', backgroundColor: 'navy', border: '1px solid navy', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}} />
            </div>

            <CardBody>
              <CardSubtitle>
                Welcome to Telangana Nijam News - Telugu Daily Newspaper, which provide Today's News Headlines in Telugu. Read Epaper of Nijam News for updates Telangana and Andhra Pradesh.
              </CardSubtitle>
              
              <div style={{fontWeight: 'bold', fontStyle: 'oblique'}}>Key Points</div>

              <ul>
                <li>Telangana Nijam News Admin Panel (Full fledged).</li>
                <li>Worked on e-paper UI designs.</li>
                <li>Technologies used - React JS.</li>
              </ul>

              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{color: 'red', cursor: 'pointer', fontWeight: '600'}} onClick={() => {setOpen(false); setProject('')}}>Close</div>
              </div>
            </CardBody>
          </Card>  
        </div>}

        {project==='atumlife' && <div>
          <Card>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={Atumlife} style={{padding: '1px', height: '100px', width: '180px'}} />
            </div>

            <CardBody>
              <CardSubtitle>
                ATUM Life is the first of its kind Net Zero Sustainability Experience Centre located in metropolitan cities. We offer a diverse range of certified sustainable products ranging from lifestyle to FMCG, from apparel to automobiles and everything in between. 
                Through our Experience Centre, we aspire to create a better, greener world and enable sustainable and renewable living practices among people.
                Provide services like In-store shopping, In-store pick-up and online delivery.
              </CardSubtitle>
              
              <div style={{fontWeight: 'bold', fontStyle: 'oblique'}}>Key Points</div>

              <ul>
                <li>ATUM Life Admin Panel (Full fledged).</li>
                <li>Worked on integration of Firebase (Signup - google, facebook) and paytm payment gateway for ATUM Life Ecommerce Web Site.</li>
                <li>Worked on Wallet page (mechanism) in ATUM Life Mobile.</li>
                <li>Technologies used - React JS, React Native, Firebase, PayTM Payment Gateway(Web & Mobile).</li>
              </ul>

              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{color: 'red', cursor: 'pointer', fontWeight: '600'}} onClick={() => {setOpen(false); setProject('')}}>Close</div>
              </div>
            </CardBody>
          </Card>  
        </div>}

        {project==='plugxr' && <div>
          <Card>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={Plugxr} style={{padding: '15px', height: '100px'}} />
            </div>

            <CardBody>
              <CardSubtitle>
                Plug XR is an Augmented Reality (AR), Virtual reality (VR), Mixed Reality (MR) / Extended Reality (XR) with an aim to accelerate and expand the XR market for everyone with ZERO Coding & Dependency to create & publish XR Apps in minutes while supporting the XR Ecosystem.
              </CardSubtitle>
              
              <div style={{fontWeight: 'bold', fontStyle: 'oblique'}}>Key Points</div>

              <ul>
                <li>Full UI and Ecommerce part of the project (Full fledged).</li>
                <li>Worked on integration of Firebase (Signup - google, facebook).</li>
                <li>Technologies used - React JS, Three JS, Firebase, WebXR Flutter, Unity.</li>
              </ul>

              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{color: 'red', cursor: 'pointer', fontWeight: '600'}} onClick={() => {setOpen(false); setProject('')}}>Close</div>
              </div>
            </CardBody>
          </Card>
        </div>}

        {project==='linview' && <div>
          <Card>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <img src={Linview} style={{padding: '28px', height: '100px', width: '200px', backgroundColor: '#222f3f'}} />
            </div> 

            <CardBody>
              <CardSubtitle>
                Linview provides the user to store the data, it shows the cloud management. 
                It can alert the user or administrator about data limit, new users, user expiration and failed operations like create and delete snapshots, invalid credentials, failed alerts.
              </CardSubtitle>
              
              <div style={{fontWeight: 'bold', fontStyle: 'oblique'}}>Key Points</div>

              <ul>
                <li>Worked on Admin panel & User Module (Full fledged).</li>
                <li>Technologies used - React JS, CSS, Bootstrap.</li>
              </ul>
              
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{color: 'red', cursor: 'pointer', fontWeight: '600'}} onClick={() => {setOpen(false); setProject('')}}>Close</div>
              </div>
            </CardBody>
          </Card>
        </div>}

        {project==='splitdeals' && <div>
          <Card>
            <div style={{padding: '28px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div style={{color: '#000', fontSize: '29px', fontWeight: '700'}}>Split</div>
              <div style={{color: 'rgb(255, 0, 98)', fontSize: '29px', fontWeight: '700'}}>&nbsp;Deals</div>
            </div>

            <CardBody>
              <CardSubtitle>
                Split Deals brings you the best shopping deals around you and across the city. 
                Split deals had a wide range of categories. 
                You could probably get access to all the deals across the city.
              </CardSubtitle>
              
              <div style={{fontWeight: 'bold', fontStyle: 'oblique'}}>Key Points</div>

              <ul>
                <li>Split Deal's Admin Panel (Full fledged).</li>
                <li>Technologies used - React JS, React Native, CSS.</li>
              </ul>
              
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{color: 'red', cursor: 'pointer', fontWeight: '600'}} onClick={() => {setOpen(false); setProject('')}}>Close</div>
              </div>
            </CardBody>
          </Card>
        </div>}

        {project==='meebazaar' && <div>
          <Card>
            <div style={{padding: '28px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div style={{color: 'navy', fontSize: '29px', fontWeight: '700'}}>Mee</div>
              <div style={{color: 'orangered', fontSize: '29px', fontWeight: '700'}}>&nbsp;Bazaar</div>
            </div>
            
            <CardBody>
              <CardSubtitle>
                MEE BAZAAR is an online grocery store which is one stop solution for all the GROCERY’s, Beauty products & Regular home needs. 
                Our Expertise lies in catering the exact needs of customer with high quality pulses and food grains. 
                We are specialized in showcasing wide variety of product Range with economy pricing which suits the pocket of common man.
              </CardSubtitle>
              
              <div style={{fontWeight: 'bold', fontStyle: 'oblique'}}>Key Points</div>

              <ul>
                <li>MEE BAZAAR Admin Panel (Full fledged).</li>
                <li>Worked on Mobile App (Cart Page).</li>
                <li>Technologies used - React JS, React Native, CSS.</li>
              </ul>
              
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{color: 'red', cursor: 'pointer', fontWeight: '600'}} onClick={() => {setOpen(false); setProject('')}}>Close</div>
              </div>
            </CardBody>
          </Card>
        </div>}

        {project==='gifteria' && <div>
          <Card>
            <div style={{padding: '28px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
              <div style={{color: '#0569D1', fontSize: '29px', fontWeight: '800'}}>GIFTERIA</div>
            </div>
            
            <CardBody>
              <CardSubtitle>
                Online Gift Store-Personalized Gifts, Frames, Micro Art Pencils, Photo Cakes, Birthday Gifts, Mother Gifts, Father Gifts etc. 
                This is an ecommerce native mobile app developed using React Native with multiple integrations.
              </CardSubtitle>
              
              <div style={{fontWeight: 'bold', fontStyle: 'oblique'}}>Key Points</div>

              <ul>
                <li>Gifteria Admin Panel (Full fledged).</li>
                <li>Worked on Mobile - Home & Product page.</li>
                <li>Technologies used - React JS, React Native, CSS.</li>
              </ul>
              
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{color: 'red', cursor: 'pointer', fontWeight: '600'}} onClick={() => {setOpen(false); setProject('')}}>Close</div>
              </div>
            </CardBody>
          </Card>
        </div>}
      </Dialog>
    </ScrollPanel>
  );
};

useGLTF.preload("https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/react-logo/model.gltf");