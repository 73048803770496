import React from 'react';

// import PNF from '../assets/imgs/pnf.gif'
import PNF from '../assets/pnf2.gif';

export default function Dashboard(){

    return(
        <div style={{width: '100vw', height: '100vh'}}>
            <div style={{
                display: 'flex', flexDirection: 'column', 
                justifyContent: 'center', alignItems: 'center'
            }}>
                <div>
                    <img src={PNF} style={{ height: '85vh' }} />
                </div>
            </div>
        </div>
    )
};