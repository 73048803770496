import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

import Game1 from './gamecomponent/game1';

const Games = () => {
    const [selGame, setSGame] = useState('');

    return (
        <React.Fragment>
            {selGame==='' && <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
                <Col xs={12} style={{
                    textAlign: 'center', fontSize: '20px', 
                    fontWeight: 'bold'
                }}>
                    Games
                </Col>

                <Col xs={12} sm={3}>
                    <div style={{
                        background: 'linear-gradient(127deg, rgb(230, 53, 26) 10%, rgb(255, 255, 255) 50%, rgb(27, 202, 1) 90%)',
                        height: '15vh', width: '100%', borderRadius: '5px',
                        display: 'flex', justifyContent: 'center',
                        alignItems: 'center', color: '#2f4f4f',
                        fontSize: '18px', fontWeight: 'bold',
                    }} onClick={() => setSGame('marblegame')}>
                        Marble Race
                    </div>
                </Col>
            </Row>} 
            {selGame==='marblegame' && <Row>
                <Col xs={12}>
                    <Game1 show={setSGame} />
                </Col>
            </Row>}
        </React.Fragment>
    );
};

export default Games;