import React, { useEffect, useState } from 'react';

import { Container, Row } from 'reactstrap';

import { BlockUI } from 'primereact/blockui';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber'; 

import { ref, getDownloadURL, listAll } from 'firebase/storage';

import { Vortex } from 'react-loader-spinner';

import { useParams } from 'react-router-dom';

// import { 
//     ZapparCamera, ZapparCanvas,
//     InstantTracker,
// } from '@zappar/zappar-react-three-fiber';

import { storage } from '../firebase-config';

export default function ViewAR(){
    const modelListRef = ref(storage, 'models/');
    
    const { name } = useParams();

    const [selObj, setSObj] = useState({});
    const [popup, setPopup] = useState(false);
    // const [placementMode, setPlacementMode] = useState(true);

    const handleName = (names) => {
        let arr1 = names.split('/');
        let arr2 = arr1[1].split('.');
        return arr2[0];
    };

    // const Model = ({name}) => {
    //     const clock = new THREE.Clock();
    //     const gltf = useLoader(GLTFLoader, name);
    //     const mixer = new THREE.AnimationMixer(gltf.scene);
      
    //     action = mixer.clipAction(gltf.animations[0]);
    //     gltf.scene.rotateX(Math.PI / 2);
      
    //     useFrame(() => mixer.update(clock.getDelta()));

    //     return <primitive object={gltf.scene} />;
    // };

    useEffect(() => {
        document.title = `View AR - ${name}`; setPopup(true);
        
        // Model List
        listAll(modelListRef).then((res) => {
            res.items.forEach((it) => {            
                getDownloadURL(it).then((url) => {
                    if(handleName(it._location.path) === name){
                        setSObj({'name': handleName(it._location.path), 'model': url});
                        setPopup(false);
                    };
                });
            });
        });      
    }, []);
    
    return(
        <div style={{height: '100vh', width: '100vw'}}>
            <div style={{color: '#282c34', textAlign: 'center', fontSize: '18px', fontWeight: '800', padding: '1%'}}>{name}</div>
                
            <BlockUI 
                blocked={popup} fullScreen
                template={<div style={{zIndex: '1001'}}>
                    <Vortex
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="vortex-loading"
                        wrapperStyle={{}}
                        wrapperClass="vortex-wrapper"
                        colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                    />
                </div>} 
            >
                <Container>
                    <Row xs={12}>
                        {selObj && <>
                            <model-viewer
                                autoplay 
                                ar
                                ar-modes="webxr scene-viewer quick-look"
                                src={selObj.model} 
                                loading="eager"
                                shadow-intensity="1"
                                auto-rotate 
                                camera-controls
                                style={{
                                    width: '100%', height: '42vh',
                                    marginLeft: '2%', background: "#F5F5F5",
                                    border: "3px solid #808080", borderRadius: "5px",
                                    boxShadow: '7px 6px 5px 0px rgba(204,204,204,0.75)',
                                }}
                            ></model-viewer>
                        </>}
                    </Row>

                    <Row xs={12}>
                        <div style={{paddingLeft: '2vw', paddingTop: '3vh'}}>
                            <ul>
                                <li>After 3D Model loaded, Click on bottom right button. </li>
                                <li>Grab your device (Android or IOS) and Point your device on Floor or Plane Surface. </li>
                                <li>After Floor or Plane Surface detected, Then 3D Model loaded and placed on detected Floor or Plane Surface</li>
                                <li>Enjoy AR experience.</li>
                            </ul>
                        </div>
                    </Row>
                </Container>
            </BlockUI>
        </div>
    );
};