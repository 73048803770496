import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { motion, AnimatePresence } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

import { useSnapshot } from 'valtio';

import { App } from './scene';
import { state } from './store';
import './index.css';

import ReactLogo from '../../assets/logos/react.png';
import GoogleLogo from '../../assets/logos/g-logo.png';
import FacebookLogo from '../../assets/logos/f-logo.png';
import ThreeLogo from '../../assets/logos/three js.png';
import FirebaseLogo from '../../assets/logos/firebase.png';
import PolygonLogo from '../../assets/logos/polygon-logo.png';
import EtheriumLogo from '../../assets/logos/etherium-logo.png';

function Customizer() {
    const snap = useSnapshot(state);

    const handleDownload = () => {
        const link = document.createElement('a');

        link.setAttribute('download', 'canvas.png');

        link.setAttribute(
            'href', 
            document.querySelector('canvas').toDataURL('image/png').replace(
                'image/png', 
                'image/octet-stream'
            )
        );
        
        link.click();
    };

    const handleLogos = (logo) => {

        switch(logo){
            case 'react':
                return <img src={ReactLogo} alt="brand" />;
            case 'three': 
                return <img src={ThreeLogo} alt="brand" />;
            case 'firebase':
                return <img src={FirebaseLogo} alt="brand" />;
            case 'polygon': 
                return <img src={PolygonLogo} alt="brand" />;
            case 'etherium': 
                return <img src={EtheriumLogo} alt="brand" />;
            case 'google':
                return <img src={GoogleLogo} alt="brand" />;
            case 'fb': 
                return <img src={FacebookLogo} alt="brand" />;
            default: 
                return <img src={ReactLogo} alt="brand" />
        }
        
    };

    return (
        <div className="customizer">
            <Container>
                <Row className="decals">
                    {snap.decals.map((decal) => (
                        <Col xs={1} key={decal} onClick={() => (state.decal = decal)}>
                            {handleLogos(decal)}
                        </Col>
                    ))}
                </Row>

                <Row className="color-options">
                    {snap.colors.map((color) => (
                        <Col xs={1}>
                            <div
                                key={color} 
                                className='circle' 
                                style={{ background: color }} 
                                onClick={() => (state.color = color)}
                            ></div>
                        </Col>
                    ))}
                </Row>

                <button
                    className="share"
                    style={{ background: '#696969' }}
                    onClick={() => handleDownload()}
                >
                    <FontAwesomeIcon icon={faCameraRetro} style={{fontSize: '20px'}} /> 
                </button>
            </Container>  
        </div>
    );
};

const ShirtConfig = () => {
    const transition = { type: 'spring', duration: 0.8 };

    const config = {
      initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0.5 } },
      animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
      exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
    };

    return (
        <div style={{cursor: 'pointer'}}>
            <div style={{ width: '100%', height: 'auto' }}>
                <AnimatePresence>
                    <motion.section key="custom" {...config}>
                        <Customizer />
                    </motion.section>
                </AnimatePresence>

                <div style={{ width: '100%', height: '50vh' }}>
                    <App />
                </div>
               
            </div>
        </div>
    );
};

export default ShirtConfig;