import React, { useEffect, useRef } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { Hud, Stage, Grid, OrbitControls, TransformControls, Environment, OrthographicCamera } from '@react-three/drei';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { proxy, useSnapshot } from 'valtio';
import { useControls } from 'leva';
import * as THREE from 'three';

export default function EditorPage(){
    const state = proxy({ current: null});
    
    const { modes } = useControls(
        'Mode',
        {
            value: { 
                value: 'translate', 
                options: ['translate', 'rotate', 'scale'] 
            },
        }
    );

    useEffect(() => {
        document.title = '3D Editor';
    }, []);

    const Controls = () => {
        const snap = useSnapshot(state), scene = useThree((state) => state.scene);

        return (
            <>
                {snap.current && <TransformControls object={scene.getObjectByName(snap.current)} mode={modes} />}
                <OrbitControls autoRotate autoRotateSpeed={0.1} enableZoom={true} makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 0.75} />
            </>
        );
    };

    const Viewcube = ({ renderPriority = 1.5, matrix = new THREE.Matrix4() }) => {
        const mesh = useRef(null);
        const { camera, size } = useThree();
      
        useFrame(() => {
            matrix.copy(camera.matrix).invert()
            mesh.current.quaternion.setFromRotationMatrix(matrix)
        });
      
        return (
          <Hud renderPriority={renderPriority}>

            <OrthographicCamera makeDefault position={[0, 0, 100]} />

            <mesh
                ref={mesh}
                position={[size.width / 2 - 120, -(size.height / 2 - 120), 0]}
            >
                <axesHelper args={[80, 80, 80]} scale={[1, 1, 1]} />

                <axesHelper args={[80, 80, 80]} scale={[1, 1, 1]} rotation={[0, -89.5, -135.1]} />
            </mesh>

            <ambientLight intensity={1} />

            <pointLight position={[200, 200, 100]} intensity={0.5} />
          </Hud>
        );
    };

    return(
        <div className='projects'>
            <div style={{ height: '100%', width: '100%' }}>
                <Canvas gl={{ logarithmicDepthBuffer: true }} shadows camera={{ position: [-15, 0, 10], fov: 25 }}>
                    <fog attach="fog" args={['white', 5, 10.5]} />

                    <Viewcube />

                    <Stage intensity={0.5} shadows={{ type: 'accumulative', bias: -0.001 }} adjustCamera={false}>
                        {/* Models Here */}
                    </Stage>

                    <Grid renderOrder={-1} position={[0, -1, 0]} infiniteGrid cellSize={0.6} cellThickness={0.6} sectionSize={3.3} sectionThickness={1.5} sectionColor={[0.5, 0.5, 10]} fadeDistance={50} />
                    
                    <Controls />
                    
                    <EffectComposer disableNormalPass>
                        <Bloom luminanceThreshold={1} mipmapBlur />
                    </EffectComposer>
                    
                    <Environment preset="sunset" blur={0.9} />
                </Canvas>
            </div>
        </div>
    );
};