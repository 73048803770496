import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import axios from 'axios';

// import { updateDoc, doc } from 'firebase/firestore';

import './web3.css';
// import Loads from '../assets/wip.gif';
// import { db } from '../firebase-config';

// let cUser = JSON.parse(localStorage.getItem('user'));

export default function Web3 (){

    // const address = '';

    // let nfts;

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [crypto, setCrypto] = useState([]);
    const [load, setLoad] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);

    const fetchCrypto = () => {
        setLoad(true);

        axios.get(
            'https://api.coinstats.app/public/v1/coins?skip=0&limit=10000&currency=INR',
        ).then((res) => {
            setLoad(false); setCrypto(res.data.coins);
        }).catch((err) => {
            console.log(err);
        });
    };

    const cryptoLogo = (row) => {
        return <img src={row.icon} style={{height: '45px', width: '45px'}} />
    };

    const cryptoPrice = (row) => {
        return <span>₹ {row.price>5 ? row.price.toLocaleString('en-IN', {
            maximumFractionDigits: 3,
        }) : row.price.toLocaleString('en-IN', {
            maximumFractionDigits: 6,
        })}</span>
    };

    const cryptoLink = (row) => {
        return <a href={row.websiteUrl} target='_blank'>{row.websiteUrl}</a>
    };

    const cryptoName = (row) => {
        return <div><span style={{fontWeight: '400'}}>{row.name}</span> .<span style={{fontWeight: '600'}}>{row.symbol}</span></div>
    };

    const cryptoWeek = (row) => {
        return <div style={row.priceChange1w<=0 ? {color: 'red'} : {color: 'green'}}>{row.priceChange1w}</div>
    };

    const tableHeader = () => {
        return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <div>
                <InputText className='m-2' value={search} onChange={(e) => {setSearch(e.target.value); (e.target.value==='' || e.target.value===null) && fetchCrypto(); console.log(e.target.value)}} placeholder='Search...' />
            </div>
        </div>
    };
    
    const template = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
        'RowsPerPageDropdown': (options) => {
          const dropdownOptions = [
            { label: 50, value: 50 },
            { label: 100, value: 100 },
            { label: 250, value: 250 },
            { label: 500, value: 500 },
            { label: 1000, value: 1000 },
          ];
    
          return (
            <React.Fragment>
              <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
              <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} style={{width: '6rem'}} />
            </React.Fragment>
          );
        },
        'CurrentPageReport': (options) => {
          return (
            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
              {options.first} - {options.last} of {options.totalRecords}
            </span>
          )
        }
    };
    
    const onCustomPage = (event) => {
        setFirst(event.first); setRows(event.rows);
    };
    
    useEffect(() => {
        document.title = 'Web 3.0'; fetchCrypto();
    }, []);

    // useEffect(() => {
        // console.log('user wallet: ', address);

        // if(cUser && !cUser.crypto_sign_in && address){
        //     console.log('entered');
            
        //     updateDoc(doc(db, 'users', cUser.uid), {
        //         'crypto_sign_in': address
        //     });
        // }
    // }, []);

    return(
        <React.Fragment>
            <div style={{marginTop: '1%', width: '100vw', height: '100vh'}}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12}>
                            <div style={{textAlign: 'center'}}>
                                <div className='check_crypto' onClick={() => setOpen(true)}>Crypto Coins</div>
                            </div>
                        </Col>

                        <Col xs={12} sm={9} md={10}>
                            NFT Market Soon...
                        </Col>

                        <Col xs={12} sm={3} md={1}>
                            <div style={{marginTop: '2%'}}>
                                <div style={{textAlign: 'center', marginTop: '2%'}}>
                                    <Button />
                                </div>                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Dialog 
                style={{width: '95vw'}} visible={open} 
                onHide={() => setOpen(false)} 
                header={<div>Crypto Currency</div>} 
            >
                <DataTable 
                    value={crypto} loading={load} size='small' removableSort
                    dataKey="rank" globalFilter={search} paginator first={first} 
                    rows={rows} onPage={onCustomPage} paginatorTemplate={template} 
                    header={tableHeader} 
                >
                    <Column field='rank' header='Rank' sortable />
                    <Column field='icon' header='Logo' body={cryptoLogo} />
                    <Column field='name' header='Name' sortable body={cryptoName} />
                    <Column field='price' header='Price' sortable body={cryptoPrice} />
                    <Column field='priceChange1w' header='1 Week' sortable body={cryptoWeek}  />
                    <Column field='websiteUrl' header='Link' body={cryptoLink} />
                </DataTable>
            </Dialog>

        </React.Fragment>
    );
};