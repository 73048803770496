import React, { useEffect, useState } from 'react';

import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ScrollPanel } from 'primereact/scrollpanel'

import { Container, Row, Col, Card } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faStickyNote, faIdCardClip, faClipboardCheck, faRotate } from '@fortawesome/free-solid-svg-icons';

import { db } from '../firebase-config';
import { UserState } from '../dataContext';
import gLogo from '../assets/logos/g-logo.png';

export default function Dashboard(){
    const { userArr, setUArr, mode } = UserState();
    const [requests, setReqs] = useState([]);

    const [selType, setSType] = useState('users');

    const [openView, setOView] = useState(false);
    const [openEdit, setOEdit] = useState(false);
    const [openDelete, setODel] = useState(false);
    const [selObj, setObj] = useState('');
    const [load, setLoad] = useState(false);

    const getUsers = () => {
        setLoad(true);

        getDocs(
            collection(db, "users")
        ).then((res) => {
            let data = [];
             
            res.forEach((doc) => {
                data.push(doc.data()); 
            });

            setUArr(data); setLoad(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getRequests = () => {
        setLoad(true);

        getDocs(
            collection(db, "requests")
        ).then((res) => {
            let data = [];
             
            res.forEach((doc) => {
                data.push(doc.data()); 
            });

            console.log('requests ', data);
            setReqs(data); setLoad(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    const updateRequests = async (val) => {
        
        const q = query(collection(db, "requests"), where("Email", "==", val));

        const querySnapshot = await getDocs(q);

        let docID = '';

        querySnapshot.forEach((docss) => {
            docID = docss.id;
        });

        const request = doc(db, "requests", docID);

        await updateDoc(request, {
            checked: true
        });

        getRequests(); setOView(false); 
        setObj([selObj.checked=true, ...selObj]);
    };

    useEffect(() => {
        document.title = 'Dashboard'; 
        getUsers(); getRequests();
    }, []);

    const logoBody = (row) => {
        return <img src={row.img} style={{width: '45px', height: '45px', borderRadius: '5px'}} />
    };

    const actionBody = (row) => {
        return selType==='users' ? <div style={{display: 'flex', flexDirection: 'row'}} key={row.id}>
            <FontAwesomeIcon icon={faStickyNote} style={{color: 'grey', fontSize: '18px', paddingRight: '7.5px'}} onClick={() => handleView(row)} />
            <FontAwesomeIcon icon={faPen} style={{color: 'darkorange', fontSize: '18px', paddingRight: '7.5px'}} onClick={() => handleEdit(row)} />
            <FontAwesomeIcon icon={faTrash} style={{color: 'firebrick', fontSize: '18px'}} onClick={() => handleDel(row)} />
        </div> : <div style={{display: 'flex', justifyContent: 'center'}} key={row.id}>
            <FontAwesomeIcon icon={faStickyNote} style={{color: 'grey', fontSize: '18px', paddingRight: '7.5px'}} onClick={() => handleView(row)} />
        </div>
    };

    const tableHeader = (
        selType==='users' ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '-5px', marginBottom: '-5px'}}>
            <div style={{fontSize: '16px'}}>User's List</div>

            <FontAwesomeIcon 
                icon={faRotate} onClick={getUsers}
                style={{ fontSize: '18px' }} 
            /> 
        </div> : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '-5px', marginBottom: '-5px'}}>
            <div style={{fontSize: '16px'}}>Request's List</div>

            <FontAwesomeIcon 
                icon={faRotate} onClick={getRequests}
                style={{ fontSize: '18px' }} 
            /> 
        </div>
    );

    const signBody = (row) => {
        return <div>
            {row.sign_in === 'google' && <img src={gLogo} style={{width: '30px'}} /> } 
            {row.sign_in === 'admin' && <FontAwesomeIcon icon={faIdCardClip} style={{fontSize: '27px', color: '#DAA520'}} /> }
        </div>
    };

    const cryptoBody = (row) => {
        return <div>
            {row.crypto_sign_in ? <FontAwesomeIcon icon={faClipboardCheck} style={{fontSize: '25px', color: '#228B22'}} /> : ' - '}
        </div>
    };

    const handleView = (row) => {
        setObj(row); setOView(true);
    };

    const handleEdit = (row) => {
        setObj(row);
    };

    const handleDel = (row) => {
        setObj(row); setODel(true);
    };

    const submitDel = () => {
        // deleteDoc(doc(db, 'users', selObj.uid));
        setODel(false); setObj(''); getUsers();
    };

    const handleCrypto = (row) => {
        let length = row.crypto_sign_in ? row.crypto_sign_in.length : 1;
        
        return row.crypto_sign_in && row.crypto_sign_in.substring(0, 5)+'...'+row.crypto_sign_in[length-2]+row.crypto_sign_in[length-1]
    };

    const checkBody = (row) => (
        <div style={{marginLeft: '1.5vw'}}>
            {row.checked === true ? <div style={{color: 'green'}}> Yes </div> : <div style={{color: 'red'}}> No </div>}
        </div> 
    );

    const descBody = (row) => {
        let length = 15;
        return row.Info.length > length ? 
        row.Info.substring(0, length - 3) + "..." : row.Info
    };

    return(
        <ScrollPanel style={{width: 'auto', height: '90vh'}}>
            <Container>
                <Row>
                    <Col xs={12} sm={4}>
                        <Row>
                            <Col xs={6}>
                                <Card style={selType==='users' ? mode===false ? {padding: '5px', border: '2px solid #ffb900', color: '#fff'} : {padding: '5px', border: '2px solid #ffb900', backgroundColor: 'transparent', color: '#ffb900'} : mode===false ? {padding: '5px'} : {padding: '5px', backgroundColor: 'transparent', color: 'whitesmoke'}} onClick={() => setSType('users')}>
                                    <h5 style={{textAlign: 'center', textDecoration: 'underline'}}>Users</h5>

                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <h3>{userArr.length}</h3>
                                    </div>
                                </Card>
                            </Col>

                            <Col xs={6}>
                                <Card style={selType==='requests' ? mode===false ? {padding: '5px', border: '2px solid #ffb900', color: '#fff'} : {padding: '5px', border: '2px solid #ffb900', backgroundColor: 'transparent', color: '#ffb900'} : mode===false ? {padding: '5px'} : {padding: '5px', backgroundColor: 'transparent', color: 'whitesmoke'}} onClick={() => setSType('requests')}>
                                    <h5 style={{textAlign: 'center', textDecoration: 'underline'}}>Request's</h5>
                                    
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <h3>{requests.length}</h3>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} sm={8}>
                        <Card style={{  
                            marginLeft: '1.5%', 
                            marginTop: '1.5%', padding: '5px'
                        }}>
                            {selType==='users' && <DataTable 
                                value={userArr} removableSort size="small" 
                                header={tableHeader} loading={load} scrollHeight='65vh'
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                paginator currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                                rows={5} rowsPerPageOptions={[5,10,25,50,100]}
                            >
                                <Column header='Logo' body={logoBody} />
                                <Column header='Full Name' field='name' sortable />
                                <Column header='Email' field='email' sortable />
                                <Column header='Sign In Method' field='sign_in' sortable body={signBody} />
                                <Column header='Crypto Sign In' field='crypto_sign_in' sortable body={cryptoBody} />
                                <Column header='Action' body={actionBody} />
                            </DataTable>}

                            {selType==='requests' && <DataTable
                                value={requests} removableSort size="small" 
                                header={tableHeader} loading={load} scrollHeight='65vh'
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                paginator currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                                rows={5} rowsPerPageOptions={[5,10,25,50,100,250,500]}
                            >
                                <Column header='Full Name' field='Name' sortable />
                                <Column header='Email' field='Email' sortable />
                                <Column header='Info' field='Info' sortable body={descBody} />
                                <Column header='Checked' field='checked' sortable body={checkBody} />
                                <Column header='Action' body={actionBody} />
                            </DataTable>}
                        </Card>
                    </Col>
                </Row>
            </Container>
            
            {/* View */}
            <Dialog 
                visible={openView} header={<div style={{ textAlign: 'center' }}>View User</div>}
                onHide={() => setOView(false)} style={{ width: '65vw', height: 'auto' }} maximizable
            >
                <Container>
                    {selType==='users' ? <Row>
                        <Col xs={4}>
                            <img src={selObj.img} style={{ width: '120px', height: 'auto', borderRadius: '7.5px' }} />
                        </Col>
                
                        <Col xs={8}>
                            <Row>
                                <Col xs={6}>
                                    Full Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                </Col>
                                <Col xs={6}>
                                    {selObj.name}
                                </Col>

                                <Col xs={6}>
                                    Email Id &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                </Col>
                                
                                <Col xs={6}>
                                    {selObj.email}
                                </Col>

                                <Col xs={6}>
                                    Sign In &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                </Col>
                                
                                <Col xs={6}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        {signBody(selObj)}&nbsp;&nbsp;{selObj.sign_in}
                                    </div>
                                </Col>

                                <Col xs={6}>
                                    Crypto Verify &nbsp;&nbsp;:
                                </Col>
                                <Col xs={6}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        {cryptoBody(selObj)}&nbsp;&nbsp;{handleCrypto(selObj)}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row> : <Row>
                        <Col xs={12} style={{textAlign: 'left', paddingLeft: '5vw'}}>
                            Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {selObj.Name}
                        </Col>

                        <Col xs={12} style={{textAlign: 'left', paddingLeft: '5vw'}}>
                            Email &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {selObj.Email}
                        </Col>

                        <Col xs={12} style={{textAlign: 'left', paddingLeft: '5vw'}}>
                            Info &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {selObj.Info}
                        </Col>

                        <Col xs={12} style={{textAlign: 'left', paddingLeft: '5vw'}}>
                            Checked &nbsp;&nbsp;&nbsp;: {selObj.checked 
                                ? <span style={{color: 'green', fontStyle: 'oblique'}}>Yes</span>
                                : <span style={{color: 'red', fontStyle: 'oblique'}}>No</span>
                            }
                        </Col>

                        <Col xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            {selObj.checked===false && <div onClick={() => updateRequests(selObj.Email)} style={{color: 'orange', fontSize: '20px'}}>Update</div>}
                        </Col>
                    </Row>}
                </Container>
            </Dialog>

            {/* Delete */}
            <Dialog 
                visible={openDelete} onHide={() => setODel(false)}
                header={<div style={{textAlign: 'center'}}> Do you want to delete record ? </div>}
                style={{ width: '35vw' }}
            >
                <Container>
                    <Row>
                        <Col xs={6} style={{textAlign: 'right'}}>
                            <Button label='No' className='p-button-danger p-button-text' style={{ fontSize: '18px', fontWeight: 'normal' }} onClick={() => {setObj(''); setODel(false)}} />
                        </Col>

                        <Col xs={6} style={{textAlign: 'left'}}>
                            <Button label='Yes' className='p-button-success p-button-text' style={{ fontSize: '18px', fontWeight: 'normal' }} onClick={submitDel} />
                        </Col>
                    </Row>
                </Container>
            </Dialog>

            {/* Edit */}
            <Dialog
                visible={openEdit} onHide={() => {setObj(''); setOEdit(false)}}
                header='Edit User'
            >
                {/* <Grid container spacing={2}>
                    
                </Grid> */}
            </Dialog>
        </ScrollPanel>
    );
};