import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const Footer = () => {
    return (
        <Container>
            <Row>
                <Col xs={6}>
                    {/* <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        
                        <Marker position={position}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>
                    </MapContainer> */}

                    {/* <Map 
                        height={250} dprs={[2, 4]}
                        center={position} zoom={15}
                    >
                        <Marker 
                            width={45} color={'#ff6347'}
                            anchor={position} 
                        /> 
                        <Overlay anchor={position} offset={[5, 5]}>
                            <img src={HomeIcon} width={70} height={65} alt='home' />
                        </Overlay>
                    </Map> */}
                </Col>
                
                <Col xs={12} style={{ textAlign: 'center' }}>
                    <div style={{fontSize: '16px'}}>
                        <FontAwesomeIcon icon={faCopyright} style={{fontWeight: '400'}} /> {new Date().getFullYear()} - {window.location.hostname}
                    </div>      
                </Col>
            </Row>
        </Container>
        
    );
};

export default Footer;